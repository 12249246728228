import React, { useContext } from 'react'
import ForbiddenPage from 'pages/403'
import { AuthContext } from 'contexts/AuthContext'
import whitelist from 'config/rules/whitelist'

function Can({ perform, yes, no }) {
  const { me, isLoading } = useContext(AuthContext)

  const checkPermission = (permissions, action) => {
    if (whitelist.includes(action)) return true
    if (!permissions) return false
    return permissions.includes(action)
  }
  const permissions = me?.permissions || []
  return isLoading ? (
    <></>
  ) : checkPermission(permissions, perform) ? (
    yes()
  ) : (
    no()
  )
}

Can.defaultProps = {
  yes: () => null,
  no: () => <ForbiddenPage />,
}

export default Can
