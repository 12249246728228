
import { confirmAlert } from 'react-confirm-alert'
import 'assets/css/confirm-alert.css'
import { chartActions, textSpeechActions } from 'redux/actions'
import ToggleButton from 'components/buttons/ToggleButton'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const useAksesibilitas = () => {
  const { t } = useTranslation()
  const textSpeechState = useSelector((state) => state.textSpeech)
  const chartState = useSelector((state) => state.chart)
  const dispatch = useDispatch()

  const toggleTextToSpeech = () => {
    dispatch(
      textSpeechActions.setTextSpeechMode(
        textSpeechState.type === 'enable' ? 'disable' : 'enable'
      )
    )
  }

  const toggleDisplayMode = () => {
    dispatch(
      chartActions.setDisplayMode(
        chartState.type === 'tabel' ? 'grafik' : 'tabel'
      )
    )
  }


  const show = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="modal-aksesibilitas">
            <h1>{t('Pengaturan Aksesibilitas')}</h1>

            <div className="detail">
              <div className="list">
                <span className="title">{t('Tampilan Mode Tabel')}</span>
                <div>
                  <ToggleButton
                    toggled={chartState.type === 'tabel' ? true : false}
                    onClick={() => toggleDisplayMode()}
                  />
                </div>
              </div>
            </div>

            <div className="detail">
              <div className="list">
                <span className="title">{t('Mengaktifkan Teks ke Ucapan')}</span>
                <div>
                  <ToggleButton
                    toggled={textSpeechState.type === 'enable' ? true : false}
                    onClick={() => toggleTextToSpeech()}
                  />
                </div>
              </div>
            </div>
           
            <button className="onclose" onClick={onClose}>
              <i className="fa fa-close"></i>
            </button>
          </div>
        )
      },
    })
  }

  return { show }
}

export default useAksesibilitas
