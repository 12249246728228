import { supportsPassiveEvents } from 'detect-passive-events'
import objectAssign from 'object-assign'
import PropTypes from 'prop-types'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import TweenFunctions from 'tween-functions'

class Button extends React.Component {
  constructor(props) {
    super(props)

    // set default state
    this.state = { show: false }

    // default property `data`
    this.data = {
      startValue: 0,
      currentTime: 0, // store current time of animation
      startTime: null,
      rafId: null,
    }

    // bind
    this.handleClick = this.handleClick.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.scrollStep = this.scrollStep.bind(this)
    this.stopScrolling = this.stopScrolling.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.show !== this.state.show
  }

  componentDidMount() {
    this.handleScroll() // initialize state

    // Add all listeners which can start scroll
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener(
      'wheel',
      this.stopScrolling,
      supportsPassiveEvents ? { passive: true } : false
    )
    window.addEventListener(
      'touchstart',
      this.stopScrolling,
      supportsPassiveEvents ? { passive: true } : false
    )
  }

  componentWillUnmount() {
    // Remove all listeners which was registered
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('wheel', this.stopScrolling, false)
    window.removeEventListener('touchstart', this.stopScrolling, false)
  }

  /**
   * call onShow callback if passed valid props
   */
  notifyOnShow() {
    if (this.props.onShow && typeof this.props.onShow === 'function') {
      this.props.onShow()
    }
  }

  /**
   * call onHide callback if passed valid props
   */
  notifyOnHide() {
    if (this.props.onHide && typeof this.props.onHide === 'function') {
      this.props.onHide()
    }
  }

  /**
   * Evaluate show/hide this component, depend on new position
   */
  handleScroll() {
    if (
      window.pageYOffset > this.props.showUnder &&
      window.pageYOffset < this.props.hideOn
    ) {
      if (!this.state.show) {
        this.setState({ show: true })
        this.notifyOnShow()
      }
    } else {
      if (this.state.show) {
        this.setState({ show: false })
        this.notifyOnHide()
      }
    }
  }

  /**
   * Handle click on the button
   */
  handleClick() {
    this.props.handleClick()
  }

  /**
   * Calculate new position
   * and scroll screen to new position or stop scrolling
   * @param timestamp
   */
  scrollStep(timestamp) {
    if (!this.data.startTime) {
      this.data.startTime = timestamp
    }

    this.data.currentTime = timestamp - this.data.startTime

    let position = TweenFunctions[this.props.easing](
      this.data.currentTime,
      this.data.startValue,
      this.props.topPosition,
      this.props.duration
    )

    if (window.pageYOffset <= this.props.topPosition) {
      this.stopScrolling()
    } else {
      window.scrollTo(window.pageYOffset, position)
      this.data.rafId = window.requestAnimationFrame(this.scrollStep)
    }
  }

  /**
   * Stop Animation Frame
   */
  stopScrolling() {
    window.cancelAnimationFrame(this.data.rafId)
  }

  /**
   * Render component
   */
  render() {
    let propStyle = this.props.style
    let propClassName = this.props.className
    let propDataTip = this.props.tooltip
    let propId = this.props.id

    let element = (
      <div
        data-for={propId}
        data-tip={propDataTip}
        style={propStyle}
        className={propClassName}
        onClick={this.handleClick}
      >
        {this.props.children}
        {propDataTip && (
          <ReactTooltip
            id={propId}
            place={'left'}
            type={'dark'}
            effect={'solid'}
            multiline={true}
            className={'example-tip'}
          />
        )}
      </div>
    )

    let style = objectAssign({}, Button.defaultProps.style)
    style = objectAssign(style, propStyle)
    style.opacity = this.state.show ? 1 : 0
    style.visibility = this.state.show ? 'visible' : 'hidden'
    style.transitionProperty = 'opacity, visibility'

    return React.cloneElement(element, { style: style })
  }
}

// Set default props
Button.defaultProps = {
  tooltip: null,
  id: 'id',
  duration: 250,
  hideOn: 100000,
  easing: 'easeOutCubic',
  className: '',
  style: {
    position: 'fixed',
    top: 10,
    right: 30,
    cursor: 'pointer',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'linear',
    transitionDelay: '0s',
    borderRadius: '50px',
    textAlign: 'center',
    fontSize: '30px',
    zIndex: '97',
    boxShadow: '0px 0px 4px 0px rgb(181 181 181 / 37%)',
    borderBottom: 'none',
  },
  topPosition: 0,
  handleClick: () => {},
}

// Set validation property types
Button.propTypes = {
  topPosition: PropTypes.number,
  showUnder: PropTypes.number.isRequired, // show button under this position,
  hideOn: PropTypes.number, // show button under this position
  easing: PropTypes.oneOf([
    'linear',
    'easeInQuad',
    'easeOutQuad',
    'easeInOutQuad',
    'easeInCubic',
    'easeOutCubic',
    'easeInOutCubic',
    'easeInQuart',
    'easeOutQuart',
    'easeInOutQuart',
    'easeInQuint',
    'easeOutQuint',
    'easeInOutQuint',
    'easeInSine',
    'easeOutSine',
    'easeInOutSine',
    'easeInExpo',
    'easeOutExpo',
    'easeInOutExpo',
    'easeInCirc',
    'easeOutCirc',
    'easeInOutCirc',
    'easeInElastic',
    'easeOutElastic',
    'easeInOutElastic',
    'easeInBack',
    'easeOutBack',
    'easeInOutBack',
    'easeInBounce',
    'easeOutBounce',
    'easeInOutBounce',
  ]),
  duration: PropTypes.number, // seconds
  style: PropTypes.object,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
}

export const FloatButton = React.memo(Button)
