import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { authActions, modalActions } from 'redux/actions'
import { emitter } from 'utils'

export default function AuthControl() {
  const dispatch = useDispatch()

  const handleUnauthorized = () => {
    dispatch(authActions.logout())
    dispatch(modalActions.login(true))
  }

  const handleForbidden = () => {
    dispatch(modalActions.login(true))
  }

  useEffect(() => {
    emitter.on('auth/unauthorized', handleUnauthorized)
    emitter.on('auth/forbidden', handleForbidden)

    return () => {
      emitter.removeListener('auth/unauthorized')
      emitter.removeListener('auth/forbidden')
    }
  }, [])

  return <></>
}
