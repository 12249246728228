import { axios } from 'utils'

export const chartService = {
  get,
}

function get(params) {
  const { chart, type, query, page, limit } = params
  return axios.post(`/data/${type}/${chart}${query}`, { page, limit })
}
