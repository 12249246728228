import { statisticConstants } from '../constants'

const defaultState = {
  user: {
    loading: false,
    data: null,
    error: null,
  },

  summary: {
    loading: false,
    data: null,
    error: null,
  },

  top_page: {
    loading: false,
    data: null,
    error: null,
  },

  visitor: {
    loading: false,
    data: null,
    error: null,
  },

  country: {
    loading: false,
    data: null,
    error: null,
  },
}

export function statistic(state = defaultState, action) {
  switch (action.type) {
    // GET USER STATISTIC
    case statisticConstants.STATISTIC_USER_REQUEST:
      return {
        ...state,
        user: {
          ...state.user,
          loading: true,
        },
      }

    case statisticConstants.STATISTIC_USER_SUCCESS:
      return {
        ...state,
        user: {
          data: action.res.data,
        },
      }

    case statisticConstants.STATISTIC_USER_FAILURE:
      return {
        ...state,
        user: {
          error: action.error,
        },
      }

    // GET SUMMARY
    case statisticConstants.STATISTIC_SUMMARY_PAGE_REQUEST:
      return {
        ...state,
        summary: {
          ...state.top_page,
          loading: true,
        },
      }

    case statisticConstants.STATISTIC_SUMMARY_PAGE_SUCCESS:
      return {
        ...state,
        summary: {
          data: action.res.data,
        },
      }

    case statisticConstants.STATISTIC_SUMMARY_PAGE_FAILURE:
      return {
        ...state,
        summary: {
          error: action.error,
        },
      }

    // GET TOP PAGE
    case statisticConstants.STATISTIC_TOP_PAGE_REQUEST:
      return {
        ...state,
        top_page: {
          ...state.top_page,
          loading: true,
        },
      }

    case statisticConstants.STATISTIC_TOP_PAGE_SUCCESS:
      return {
        ...state,
        top_page: {
          data: action.res.data,
        },
      }

    case statisticConstants.STATISTIC_TOP_PAGE_FAILURE:
      return {
        ...state,
        top_page: {
          error: action.error,
        },
      }

    // GET VISITOR
    case statisticConstants.STATISTIC_VISITOR_REQUEST:
      return {
        ...state,
        visitor: {
          ...state.visitor,
          loading: true,
        },
      }

    case statisticConstants.STATISTIC_VISITOR_SUCCESS:
      return {
        ...state,
        visitor: {
          data: action.res.data,
        },
      }

    case statisticConstants.STATISTIC_VISITOR_FAILURE:
      return {
        ...state,
        visitor: {
          error: action.error,
        },
      }

    // GET COUNTRY
    case statisticConstants.STATISTIC_COUNTRY_REQUEST:
      return {
        ...state,
        country: {
          ...state.country,
          loading: true,
        },
      }

    case statisticConstants.STATISTIC_COUNTRY_SUCCESS:
      return {
        ...state,
        country: {
          data: action.res.data,
        },
      }

    case statisticConstants.STATISTIC_COUNTRY_FAILURE:
      return {
        ...state,
        country: {
          error: action.error,
        },
      }

    default:
      return state
  }
}
