import Layout from '../layout/App'
import Loader from './Loader'

export default function SuspensePage() {
  return (
    <Layout className="bg-grey mb-10">
      <div className="mt-10">
        <Loader />
      </div>
    </Layout>
  )
}
