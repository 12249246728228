import React from 'react'
import { ErrorMessage } from './ErrorMessage'

export const Input = React.memo(
  ({
    name,
    label,
    register,
    errors,
    rules = {},
    placeholder = '',
    type = 'text',
    unit = '',
    defaultValue = '',
    style = {},
    styleInput = {},
    isReadOnly = false,
    isDisabled = false
  }) => {
    if (unit) {
      return (
        <>
          <div
            className={
              'form-group form-group-default input-group ' +
              (rules.required ? ' required ' : '') +
              (errors[name] ? ' has-error ' : '') +
              (isDisabled ? ' disabled ' : '')
            }
            style={style}
          >
            <div className="form-input-group">
              <label>{label}</label>
              <input
                type={type}
                name={name}
                defaultValue={defaultValue}
                placeholder={placeholder}
                ref={register(rules)}
                className="form-control"
                autoComplete="new-password"
                readOnly={isReadOnly || isDisabled}
                style={styleInput}
              />
            </div>
            <div className="input-group-append ">
              <span className="input-group-text">{unit}</span>
            </div>
          </div>
          <ErrorMessage errors={errors} name={name} label={label} />
        </>
      )
    }

    return (
      <>
        <div
          className={
            'form-group form-group-default' +
            (rules.required ? ' required ' : '') +
            (errors[name] ? ' has-error ' : '') +
            (isDisabled ? ' disabled ' : '')
          }
          style={style}
        >
          <label>{label}</label>
          <input
            type={type}
            name={name}
            defaultValue={defaultValue}
            placeholder={placeholder}
            ref={register(rules)}
            className="form-control"
            autoComplete="new-password"
            readOnly={isReadOnly || isDisabled}
            style={styleInput}
          />
        </div>
        <ErrorMessage errors={errors} name={name} label={label} />
      </>
    )
  }
)
