import { modalConstants } from '../constants'

let modalState = {
  login: false,
  advance_search: false,
  configuration_unduh: false,
  narapidana_search: false,
  putusan_search: false,
  report: false,
}

export function modal(state = modalState, action) {
  switch (action.type) {
    case modalConstants.LOGIN_TOGGLE:
      return {
        login: action.payload.open,
      }

    case modalConstants.ADVANCE_SEARCH_TOGGLE:
      return {
        advance_search: action.payload.open,
      }

    case modalConstants.KONFIGURASI_UNDUH_TOGGLE:
      return {
        configuration_unduh: action.payload.open,
      }

    case modalConstants.NARAPIDANA_SEARCH_TOGGLE:
      return {
        narapidana_search: action.payload.open,
      }

    case modalConstants.PUTUSAN_SEARCH_TOGGLE:
      return {
        putusan_search: action.payload.open,
      }

    case modalConstants.REPORT_TOGGLE:
      return {
        report: action.payload.open,
      }

    default:
      return state
  }
}
