import React from 'react'
import { ErrorMessage } from './ErrorMessage'
import { default as DatePickerReact } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Controller } from 'react-hook-form'

export const DatePicker = React.memo(
  ({
    name,
    label,
    control,
    errors,
    defaultValue = null,
    rules = {},
    placeholder = '',
    isReadOnly = false,
  }) => {
    return (
      <>
        <div
          className={
            'form-group form-group-default' +
            (rules.required ? ' required ' : '') +
            (errors[name] ? ' has-error ' : '')
          }
          style={{ overflow: 'inherit' }}
        >
          <label>{label}</label>
          <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ onChange, value }) => (
              <DatePickerReact
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                showMonthDropdown
                className="form-control"
                key={name}
                placeholder={placeholder}
                selected={value}
                onChange={onChange}
                readOnly={isReadOnly}
              />
            )}
          />
        </div>
        <ErrorMessage errors={errors} name={name} label={label} />
      </>
    )
  }
)
