import { authConstants } from '../constants'

const initialState = {
  loading: false,
  error: null,
  reset: {
    loading: false,
    result: null,
    error: null,
  },
}
export function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }
    case authConstants.LOGOUT:
      return {
        ...state,
        userLogin: null,
      }

    case authConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        reset: {
          ...state.reset,
          loading: true,
        },
      }
    case authConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        reset: {
          result: true,
        },
      }
    case authConstants.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        reset: {
          error: action.error,
        },
      }
    default:
      return state
  }
}
