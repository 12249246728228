export const putusanDraftConstants = {
  PUTUSAN_DRAFT_GETALL_REQUEST: 'PUTUSAN_DRAFT_GETALL_REQUEST',
  PUTUSAN_DRAFT_GETALL_SUCCESS: 'PUTUSAN_DRAFT_GETALL_SUCCESS',
  PUTUSAN_DRAFT_GETALL_FAILURE: 'PUTUSAN_DRAFT_GETALL_FAILURE',

  PUTUSAN_DRAFT_GET_REQUEST: 'PUTUSAN_DRAFT_GET_REQUEST',
  PUTUSAN_DRAFT_GET_SUCCESS: 'PUTUSAN_DRAFT_GET_SUCCESS',
  PUTUSAN_DRAFT_GET_FAILURE: 'PUTUSAN_DRAFT_GET_FAILURE',

  PUTUSAN_DRAFT_RINGKASAN_REQUEST: 'PUTUSAN_DRAFT_RINGKASAN_REQUEST',
  PUTUSAN_DRAFT_RINGKASAN_SUCCESS: 'PUTUSAN_DRAFT_RINGKASAN_SUCCESS',
  PUTUSAN_DRAFT_RINGKASAN_FAILURE: 'PUTUSAN_DRAFT_RINGKASAN_FAILURE',

  PUTUSAN_DRAFT_DETAILS_REQUEST: 'PUTUSAN_DRAFT_DETAILS_REQUEST',
  PUTUSAN_DRAFT_DETAILS_SUCCESS: 'PUTUSAN_DRAFT_DETAILS_SUCCESS',
  PUTUSAN_DRAFT_DETAILS_FAILURE: 'PUTUSAN_DRAFT_DETAILS_FAILURE',

  PUTUSAN_DRAFT_CREATE_REQUEST: 'PUTUSAN_DRAFT_CREATE_REQUEST',
  PUTUSAN_DRAFT_CREATE_SUCCESS: 'PUTUSAN_DRAFT_CREATE_SUCCESS',
  PUTUSAN_DRAFT_CREATE_FAILURE: 'PUTUSAN_DRAFT_CREATE_FAILURE',

  PUTUSAN_DRAFT_UPDATE_REQUEST: 'PUTUSAN_DRAFT_UPDATE_REQUEST',
  PUTUSAN_DRAFT_UPDATE_SUCCESS: 'PUTUSAN_DRAFT_UPDATE_SUCCESS',
  PUTUSAN_DRAFT_UPDATE_FAILURE: 'PUTUSAN_DRAFT_UPDATE_FAILURE',

  PUTUSAN_DRAFT_DELETE_REQUEST: 'PUTUSAN_DRAFT_DELETE_REQUEST',
  PUTUSAN_DRAFT_DELETE_SUCCESS: 'PUTUSAN_DRAFT_DELETE_SUCCESS',
  PUTUSAN_DRAFT_DELETE_FAILURE: 'PUTUSAN_DRAFT_DELETE_FAILURE',

  PUTUSAN_DRAFT_FILE_DELETE_REQUEST: 'PUTUSAN_DRAFT_FILE_DELETE_REQUEST',
  PUTUSAN_DRAFT_FILE_DELETE_SUCCESS: 'PUTUSAN_DRAFT_FILE_DELETE_SUCCESS',
  PUTUSAN_DRAFT_FILE_DELETE_FAILURE: 'PUTUSAN_DRAFT_FILE_DELETE_FAILURE',

  PUTUSAN_DRAFT_VERIFY_REQUEST: 'PUTUSAN_DRAFT_VERIFY_REQUEST',
  PUTUSAN_DRAFT_VERIFY_SUCCESS: 'PUTUSAN_DRAFT_VERIFY_SUCCESS',
  PUTUSAN_DRAFT_VERIFY_FAILURE: 'PUTUSAN_DRAFT_VERIFY_FAILURE',

  PUTUSAN_DRAFT_UPLOAD_REQUEST: 'PUTUSAN_DRAFT_UPLOAD_REQUEST',
  PUTUSAN_DRAFT_UPLOAD_SUCCESS: 'PUTUSAN_DRAFT_UPLOAD_SUCCESS',
  PUTUSAN_DRAFT_UPLOAD_FAILURE: 'PUTUSAN_DRAFT_UPLOAD_FAILURE',

  PUTUSAN_DRAFT_DOWNLOAD_REQUEST: 'PUTUSAN_DRAFT_DOWNLOAD_REQUEST',
  PUTUSAN_DRAFT_DOWNLOAD_SUCCESS: 'PUTUSAN_DRAFT_DOWNLOAD_SUCCESS',
  PUTUSAN_DRAFT_DOWNLOAD_FAILURE: 'PUTUSAN_DRAFT_DOWNLOAD_FAILURE',
}
