import { searchConstants } from '../constants'

const defaultState = {
  keyword: {
    loading: false,
    list: [],
    error: null,
  },
  provinsi: {
    loading: false,
    data: null,
    error: null,
  },
  kabkota: {
    loading: false,
    data: null,
    error: null,
  },
  jaringan: {
    loading: false,
    data: null,
    error: null,
  },
  tahun: {
    loading: false,
    data: null,
    error: null,
  },
  hakim: {
    loading: false,
    data: null,
    error: null,
  },
  motif: {
    loading: false,
    data: null,
    error: null,
  },
  wilayah_pengadilan: {
    loading: false,
    data: null,
    error: null,
  },
  perbuatan: {
    loading: false,
    data: null,
    error: null,
  },
  pasal: {
    loading: false,
    data: null,
    error: null,
  },
  search_putusan: {
    loading: false,
    list: null,
    error: null,
  }
}

export function search(state = defaultState, action) {
  switch (action.type) {
    // GET
    case searchConstants.SEARCH_GET_REQUEST:
      return {
        ...state,
        keyword: {
          ...state.keyword,
          loading: true,
        },
      }

    case searchConstants.SEARCH_GET_SUCCESS:
      return {
        ...state,
        keyword: {
          list: action.res.data,
        },
      }

    case searchConstants.SEARCH_GET_FAILURE:
      return {
        ...state,
        keyword: {
          error: action.error,
          list: [],
          meta: { total: 0 },
        },
      }

    case searchConstants.SEARCH_CLEAR_SUCCESS:
      return {
        ...state,
        keyword: {
          list: [],
          meta: { total: 0 },
        },
      }

    // PROVINSI
    case searchConstants.SEARCH_PROVINSI_REQUEST:
      return {
        ...state,
        provinsi: {
          ...state.provinsi,
          loading: true,
        },
      }

    case searchConstants.SEARCH_PROVINSI_SUCCESS:
      return {
        ...state,
        provinsi: {
          data: action.res.data,
        },
      }

    case searchConstants.SEARCH_PROVINSI_FAILURE:
      return {
        ...state,
        provinsi: {
          error: action.error,
        },
      }

    // KABKOTA
    case searchConstants.SEARCH_KABKOTA_REQUEST:
      return {
        ...state,
        kabkota: {
          ...state.kabkota,
          loading: true,
        },
      }

    case searchConstants.SEARCH_KABKOTA_SUCCESS:
      return {
        ...state,
        kabkota: {
          data: action.res.data,
        },
      }

    case searchConstants.SEARCH_KABKOTA_FAILURE:
      return {
        ...state,
        kabkota: {
          error: action.error,
        },
      }

    // JARINGAN
    case searchConstants.SEARCH_JARINGAN_REQUEST:
      return {
        ...state,
        jaringan: {
          ...state.jaringan,
          loading: true,
        },
      }

    case searchConstants.SEARCH_JARINGAN_SUCCESS:
      return {
        ...state,
        jaringan: {
          data: action.res.data,
        },
      }

    case searchConstants.SEARCH_JARINGAN_FAILURE:
      return {
        ...state,
        jaringan: {
          error: action.error,
        },
      }

    // TAHUN
    case searchConstants.SEARCH_TAHUN_REQUEST:
      return {
        ...state,
        tahun: {
          ...state.tahun,
          loading: true,
        },
      }

    case searchConstants.SEARCH_TAHUN_SUCCESS:
      return {
        ...state,
        tahun: {
          data: action.res.data,
        },
      }

    case searchConstants.SEARCH_TAHUN_FAILURE:
      return {
        ...state,
        tahun: {
          error: action.error,
        },
      }

    // HAKIM
    case searchConstants.SEARCH_HAKIM_REQUEST:
      return {
        ...state,
        hakim: {
          ...state.hakim,
          loading: true,
        },
      }

    case searchConstants.SEARCH_HAKIM_SUCCESS:
      return {
        ...state,
        hakim: {
          data: action.res.data,
        },
      }

    case searchConstants.SEARCH_HAKIM_FAILURE:
      return {
        ...state,
        hakim: {
          error: action.error,
        },
      }

    // MOTIF
    case searchConstants.SEARCH_MOTIF_REQUEST:
      return {
        ...state,
        motif: {
          ...state.motif,
          loading: true,
        },
      }

    case searchConstants.SEARCH_MOTIF_SUCCESS:
      return {
        ...state,
        motif: {
          data: action.res.data,
        },
      }

    case searchConstants.SEARCH_MOTIF_FAILURE:
      return {
        ...state,
        motif: {
          error: action.error,
        },
      }

    // WILAYAH_PENGADILAN
    case searchConstants.SEARCH_WILAYAH_PENGADILAN_REQUEST:
      return {
        ...state,
        wilayah_pengadilan: {
          ...state.wilayah_pengadilan,
          loading: true,
        },
      }

    case searchConstants.SEARCH_WILAYAH_PENGADILAN_SUCCESS:
      return {
        ...state,
        wilayah_pengadilan: {
          data: action.res.data,
        },
      }

    case searchConstants.SEARCH_WILAYAH_PENGADILAN_FAILURE:
      return {
        ...state,
        wilayah_pengadilan: {
          error: action.error,
        },
      }

    // PERBUATAN
    case searchConstants.SEARCH_PERBUATAN_REQUEST:
      return {
        ...state,
        perbuatan: {
          ...state.perbuatan,
          loading: true,
        },
      }

    case searchConstants.SEARCH_PERBUATAN_SUCCESS:
      return {
        ...state,
        perbuatan: {
          data: action.res.data,
        },
      }

    case searchConstants.SEARCH_PERBUATAN_FAILURE:
      return {
        ...state,
        perbuatan: {
          error: action.error,
        },
      }

    // PASAL
    case searchConstants.SEARCH_PASAL_REQUEST:
      return {
        ...state,
        pasal: {
          ...state.pasal,
          loading: true,
        },
      }

    case searchConstants.SEARCH_PASAL_SUCCESS:
      return {
        ...state,
        pasal: {
          data: action.res.data,
        },
      }
    
    case searchConstants.SEARCH_PASAL_FAILURE:
      return {
        ...state,
        pasal: {
          error: action.error,
        },
      }

    // PUTUSAN
    case searchConstants.SEARCH_PUTUSAN_REQUEST:
      return {
        ...state,
        search_putusan: {
          ...state.putusan,
          loading: true,
        },
      }

    case searchConstants.SEARCH_PUTUSAN_SUCCESS:
      return {
        ...state,
        search_putusan: {
          list: action.res.data,
        },
      }
    
    case searchConstants.SEARCH_PUTUSAN_FAILURE:
      return {
        ...state,
        search_putusan: {
          error: action.error,
        },
      }

    default:
      return state
  }
}
