import React from 'react'
import { ErrorMessage } from './ErrorMessage'

export const Textarea = React.memo(
  ({
    name,
    label,
    register,
    errors,
    defaultValue = '',
    rules = {},
    placeholder = '',
    isReadOnly = false,
    isDisabled = false
  }) => {
    return (
      <>
        <div
          className={
            'form-group form-group-default' +
            (rules.required ? ' required ' : '') +
            (errors[name] ? ' has-error ' : '') +
            (isDisabled ? ' disabled ' : '')
          }
          style={{ overflow: 'inherit' }}
        >
          <label>{label}</label>
          <textarea
            name={name}
            defaultValue={defaultValue}
            placeholder={placeholder}
            ref={register(rules)}
            style={{ minHeight: '35px' }}
            className="form-control"
            readOnly={isReadOnly}
          />
        </div>
        <ErrorMessage errors={errors} name={name} label={label} />
      </>
    )
  }
)
