export const userConstants = {
  USER_GET_ME_REQUEST: 'USER_GET_ME_REQUEST',
  USER_GET_ME_SUCCESS: 'USER_GET_ME_SUCCESS',
  USER_GET_ME_FAILURE: 'USER_GET_ME_FAILURE',

  USER_GETALL_REQUEST: 'USER_GETALL_REQUEST',
  USER_GETALL_SUCCESS: 'USER_GETALL_SUCCESS',
  USER_GETALL_FAILURE: 'USER_GETALL_FAILURE',

  USER_GET_REQUEST: 'USER_GET_REQUEST',
  USER_GET_SUCCESS: 'USER_GET_SUCCESS',
  USER_GET_FAILURE: 'USER_GET_FAILURE',

  USER_CREATE_REQUEST: 'USER_CREATE_REQUEST',
  USER_CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
  USER_CREATE_FAILURE: 'USER_CREATE_FAILURE',

  USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',

  USER_DELETE_REQUEST: 'USER_DELETE_REQUEST',
  USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  USER_DELETE_FAILURE: 'USER_DELETE_FAILURE',
}
