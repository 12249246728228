export const putusanConstants = {
  PUTUSAN_GETALL_REQUEST: 'PUTUSAN_GETALL_REQUEST',
  PUTUSAN_GETALL_SUCCESS: 'PUTUSAN_GETALL_SUCCESS',
  PUTUSAN_GETALL_FAILURE: 'PUTUSAN_GETALL_FAILURE',

  PUTUSAN_GET_REQUEST: 'PUTUSAN_GET_REQUEST',
  PUTUSAN_GET_SUCCESS: 'PUTUSAN_GET_SUCCESS',
  PUTUSAN_GET_FAILURE: 'PUTUSAN_GET_FAILURE',

  PUTUSAN_RINGKASAN_REQUEST: 'PUTUSAN_RINGKASAN_REQUEST',
  PUTUSAN_RINGKASAN_SUCCESS: 'PUTUSAN_RINGKASAN_SUCCESS',
  PUTUSAN_RINGKASAN_FAILURE: 'PUTUSAN_RINGKASAN_FAILURE',

  PUTUSAN_DETAILS_REQUEST: 'PUTUSAN_DETAILS_REQUEST',
  PUTUSAN_DETAILS_SUCCESS: 'PUTUSAN_DETAILS_SUCCESS',
  PUTUSAN_DETAILS_FAILURE: 'PUTUSAN_DETAILS_FAILURE',

  PUTUSAN_DOWNLOAD_REQUEST: 'PUTUSAN_DOWNLOAD_REQUEST',
  PUTUSAN_DOWNLOAD_SUCCESS: 'PUTUSAN_DOWNLOAD_SUCCESS',
  PUTUSAN_DOWNLOAD_FAILURE: 'PUTUSAN_DOWNLOAD_FAILURE',

  PUTUSAN_REPORT_REQUEST: 'PUTUSAN_REPORT_REQUEST',
  PUTUSAN_REPORT_SUCCESS: 'PUTUSAN_REPORT_SUCCESS',
  PUTUSAN_REPORT_FAILURE: 'PUTUSAN_REPORT_FAILURE',
}
