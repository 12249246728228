import { axios } from 'utils'

export const putusanService = {
  getAll,
  get,
  download,
  report
}

function getAll(params) {
  const page = params.page || 1
  const limit = params.limit || 10
  const start = (page - 1) * limit

  let queryString = `?s=${start}&l=${limit}`
  if (params.query) {
    queryString += `&${params.query}`
  }
  if (params.draft) {
    queryString += `&draft=true`
  }

  return axios.get(`/putusan/verified${queryString}`)
}

function get(param) {
  let uri = ''
  let queryString = ''

  if (param.draft) {
    queryString += `?draft=true`
  }

  if (param.id) {
    uri = `/putusan/verified/${param.id}`
    if (param.type === 'ringkasan') uri = `${uri}/ringkasan`
    if (param.type === 'detail') uri = `${uri}/detail`

    uri = `${uri}${queryString}`
  }

  return axios.get(uri)
}

function download(param) {
  if (param.filename) {
    return axios.post(
      `/putusan/verified/download/${encodeURI(param.filename)}/original`,
      {},
      { responseType: 'blob' }
    )
  } else if (param.id) {
    return axios.post(
      `/putusan/verified/download/${param.id}/extracted`,
      {},
      { responseType: 'blob' }
    )
  } else if (param.items) {
    return axios.post(
      `/putusan/verified/download/multiple/extracted`,
      {
        items: param.items,
      },
      { responseType: 'blob' }
    )
  }
}

function report(payload) {
  return axios.post(`/report`, payload)
}