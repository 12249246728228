import { faqConstants } from '../constants'
import { faqService } from '../services'
export const faqActions = {
  getAll,
}

function getAll(params = {}) {
  return (dispatch) => {
    dispatch({ type: faqConstants.FAQ_GET_DISPLAY_REQUEST, params })
    faqService.getAll(params).then(
      (res) => {
        dispatch({
          type: faqConstants.FAQ_GET_DISPLAY_SUCCESS,
          res,
        })
      },
      (error) =>
        dispatch({
          type: faqConstants.FAQ_GET_DISPLAY_FAILURE,
          error,
        })
    )
  }
}
