import React from 'react'

export const SubmitButton = React.memo(
  ({ className = '', children, isLoading = false }) => {
    return (
      <button
        aria-label=""
        className={`btn btn-primary btn-lg ${className}`}
        type="submit"
        disabled={isLoading}
      >
        {isLoading && (
          <div
            className="spinner-border spinner-border-sm mr-1"
            role="status"
            aria-hidden="true"
          ></div>
        )}
        {children}
      </button>
    )
  }
)
