import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import style from './FooterLink.module.css'
import cds from 'assets/img/cds.png'
import { AppContext } from 'contexts/AppContext'
import { useTranslation } from 'react-i18next';
import imgRespomsiveVoice from './responsivevoice.png'
import useAksesibilitas from 'components/aksesibilitas/aksesibilitas'

const FooterLink = () => {
  const aksesibilitas = useAksesibilitas()
  const { footerDescription, sosmedLink } = useContext(AppContext)
  const { t } = useTranslation();

  return (
    <>
      <div className="footer-logo-cds">
        <a href="https://cds.or.id" target="_blank" rel="noreferrer">
          <img src={cds} alt="Logo CDS" />
        </a>
      </div>
      <div
        className={`pb-0 mb-0 justify-content-center text-light ${style.footer}`}
      >
        <footer>
          <div className="row my-1 justify-content-center py-3 py-xl-5 container-fluid container-fixed-lg">
            <div className="col-12 col-md-11 py-3 py-xl-5">
              <div className="row align-text-top px-3 px-xl-0">
                <div className="col-12 col-md-7">
                  <div
                    tabIndex={0}
                    className="pr-xl-5 px-2 description"
                    dangerouslySetInnerHTML={{
                      __html: footerDescription,
                    }}
                  />
                </div>
                <div className="row col-12 col-md-5">
                  <div className="col-md-5 col-6">
                    <ul className="list-unstyled px-2 pt-3 pt-xl-0">
                      <li>
                        <Link to="/">{t('Beranda')}</Link>
                      </li>
                      <li>
                        <Link to="/publikasi">{t('Publikasi')}</Link>
                      </li>
                      <li>
                        <Link to="/berita">{t('Berita')}</Link>
                      </li>
                      <li>
                        <Link to="/putusan">{t('File Putusan')}</Link>
                      </li>
                      <li>
                        <Link to="/unduh">{t('Unduh')}</Link>
                      </li>
                      <li>
                        <Link to="/about">{t('Tentang Database')}</Link>
                      </li>
                      <li>
                        <Link to="/faq">{t('FAQ')}</Link>
                      </li>
                      <li>
                        <div
                          onClick={() => aksesibilitas.show()}
                          style={{ cursor: 'pointer' }}
                        >
                          {t('Aksesibilitas')}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-5 col-6">
                    <ul className="list-unstyled px-2 pt-3 pt-xl-0">
                      <li>
                        <Link to="/data/demografi">{t('Demografi')}</Link>
                      </li>
                      <li>
                        <Link to="/data/karakteristik-jaringan">
                        {t('Karakteristik Jaringan')}
                        </Link>
                      </li>
                      <li>
                        <Link to="/data/pola-pemidanaan">{t('Pola Pemidanaan')}</Link>
                      </li>
                      <li>
                        <Link to="/data/peta">{t('Peta')}</Link>
                      </li>
                      <li>
                        <Link to="/data/tren-variabel">{t('Tren Variabel')}</Link>
                      </li>
                    </ul>
                  </div>
                  <div class="clearfix"></div>
                  <div className="col-md-10 col-12">
                    <div className="row px-2">
                      <div className="col-3">
                        {sosmedLink.facebook !== "" &&
                          <a href={sosmedLink.facebook} target="_blank" rel="noreferrer">
                            <i class="fa fa-facebook-square icon-sosmed"></i>  
                          </a>
                        }
                        {sosmedLink.facebook === "" &&
                          <i class="fa fa-facebook-square icon-sosmed"></i>
                        }
                      </div>
                      <div className="col-3">
                        {sosmedLink.twitter !== "" &&
                          <a href={sosmedLink.twitter} target="_blank" rel="noreferrer">
                            <i class="fa fa-twitter icon-sosmed"></i>
                          </a>
                        }
                        {sosmedLink.twitter === "" &&
                          <i class="fa fa-twitter icon-sosmed"></i>
                        }
                      </div>
                      <div className="col-3">
                        {sosmedLink.instagram !== "" &&
                          <a href={sosmedLink.instagram} target="_blank" rel="noreferrer">
                            <i class="fa fa-instagram icon-sosmed"></i>
                          </a>
                        }
                        {sosmedLink.instagram === "" &&
                          <i class="fa fa-instagram icon-sosmed"></i>
                        }
                      </div>
                      <div className="col-3">
                        {sosmedLink.youtube !== "" &&
                          <a href={sosmedLink.facebook} target="_blank" rel="noreferrer">
                            <i class="fa fa-youtube-play icon-sosmed"></i>
                          </a>
                        }
                        {sosmedLink.youtube === "" &&
                          <i class="fa fa-youtube-play icon-sosmed"></i>
                        }
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div className="col-md-10 col-12 mt-4">
                    <ul className="list-unstyled px-2 pt-3 pt-xl-0">
                      <li className='attr-responsvievoice'>
                        <img src={imgRespomsiveVoice} alt="responsvievoice" />
                        <span ><a href="https://responsivevoice.org/" target='_blank'>ResponsiveVoice</a> used under <br/> <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/" target='_blank'>Non-Commercial License</a></span>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="col-xl-1 col-md-4 mt-4 mt-xl-0 mb-2 col-12 text-center">
                <a href="https://cds.or.id" target="_blank" rel="noreferrer">
                  <img src={cds} alt="Logo CDS" />
                </a>
              </div> */}
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default React.memo(FooterLink)
