import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const Breadcrumb = ({ list }) => {
  const { t } = useTranslation();
  let lists = [{ to: '/', label: 'Beranda' }, ...list]

  return (
    <nav aria-label="Breadcrumb">
      <ol className="breadcrumb">
        {lists.map((list, index) => {
          let ariaCurrent = list.active ? { 'aria-current': 'page' } : {}
          let isActiveClass = list.active ? 'active' : ''
          return (
            <li
              className={'breadcrumb-item ' + isActiveClass}
              key={index}
              {...ariaCurrent}
            >
              {list.to ? (
                <NavLink to={list.to} isActive={() => list.active}>
                {t(list.label)}
                </NavLink>
              ) : (
                <div
                  style={{
                    fontWeight: 500,
                    letterSpacing: '0.06em',
                  }}
                >
                  {t(list.label)}
                </div>
              )}
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

Breadcrumb.defaultProps = {
  list: [],
}

export default React.memo(Breadcrumb)
