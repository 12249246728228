export const searchConstants = {
  SEARCH_GET_REQUEST: 'SEARCH_GET_REQUEST',
  SEARCH_GET_SUCCESS: 'SEARCH_GET_SUCCESS',
  SEARCH_GET_FAILURE: 'SEARCH_GET_FAILURE',
  SEARCH_CLEAR_SUCCESS: 'SEARCH_CLEAR_SUCCESS',

  SEARCH_PROVINSI_REQUEST: 'SEARCH_PROVINSI_REQUEST',
  SEARCH_PROVINSI_SUCCESS: 'SEARCH_PROVINSI_SUCCESS',
  SEARCH_PROVINSI_FAILURE: 'SEARCH_PROVINSI_FAILURE',

  SEARCH_KABKOTA_REQUEST: 'SEARCH_KABKOTA_REQUEST',
  SEARCH_KABKOTA_SUCCESS: 'SEARCH_KABKOTA_SUCCESS',
  SEARCH_KABKOTA_FAILURE: 'SEARCH_KABKOTA_FAILURE',

  SEARCH_TAHUN_REQUEST: 'SEARCH_TAHUN_REQUEST',
  SEARCH_TAHUN_SUCCESS: 'SEARCH_TAHUN_SUCCESS',
  SEARCH_TAHUN_FAILURE: 'SEARCH_TAHUN_FAILURE',

  SEARCH_JARINGAN_REQUEST: 'SEARCH_JARINGAN_REQUEST',
  SEARCH_JARINGAN_SUCCESS: 'SEARCH_JARINGAN_SUCCESS',
  SEARCH_JARINGAN_FAILURE: 'SEARCH_JARINGAN_FAILURE',

  SEARCH_HAKIM_REQUEST: 'SEARCH_HAKIM_REQUEST',
  SEARCH_HAKIM_SUCCESS: 'SEARCH_HAKIM_SUCCESS',
  SEARCH_HAKIM_FAILURE: 'SEARCH_HAKIM_FAILURE',

  SEARCH_MOTIF_REQUEST: 'SEARCH_MOTIF_REQUEST',
  SEARCH_MOTIF_SUCCESS: 'SEARCH_MOTIF_SUCCESS',
  SEARCH_MOTIF_FAILURE: 'SEARCH_MOTIF_FAILURE',

  SEARCH_WILAYAH_PENGADILAN_REQUEST: 'SEARCH_WILAYAH_PENGADILAN_REQUEST',
  SEARCH_WILAYAH_PENGADILAN_SUCCESS: 'SEARCH_WILAYAH_PENGADILAN_SUCCESS',
  SEARCH_WILAYAH_PENGADILAN_FAILURE: 'SEARCH_WILAYAH_PENGADILAN_FAILURE',

  SEARCH_PERBUATAN_REQUEST: 'SEARCH_PERBUATAN_REQUEST',
  SEARCH_PERBUATAN_SUCCESS: 'SEARCH_PERBUATAN_SUCCESS',
  SEARCH_PERBUATAN_FAILURE: 'SEARCH_PERBUATAN_FAILURE',

  SEARCH_PASAL_REQUEST: 'SEARCH_PASAL_REQUEST',
  SEARCH_PASAL_SUCCESS: 'SEARCH_PASAL_SUCCESS',
  SEARCH_PASAL_FAILURE: 'SEARCH_PASAL_FAILURE',

  SEARCH_PUTUSAN_REQUEST: 'SEARCH_PUTUSAN_REQUEST',
  SEARCH_PUTUSAN_SUCCESS: 'SEARCH_PUTUSAN_SUCCESS',
  SEARCH_PUTUSAN_FAILURE: 'SEARCH_PUTUSAN_FAILURE',
}
