import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { authActions, modalActions, navigationActions } from 'redux/actions'
import LanguageSwitcher from './Language'
import Menu from './Menu'
import { ReactComponent as LogoText } from './logo.svg'

function Header(props) {
  const { me, getMe, isLogin } = useContext(AuthContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const modal = useSelector((state) => state.modal)
  const navigation = useSelector((state) => state.navigation)
  const [isOpen, setIsOpen] = useState(false)

  const handleToggleNav = (e) => {
    e.preventDefault()
    dispatch(navigationActions.toggle(!navigation.open))
  }

  const handleToggleLogin = () => {
    dispatch(modalActions.login(!modal.login))
  }

  const handleLogout = (e) => {
    e.preventDefault()
    dispatch(
      authActions.logout(() => {
        getMe()
      })
    )
    setIsOpen(false)
    history.push('/')
  }

  return (
    <div className={'header d-print-none ' + props.className}>
      <div className="container">
        <div className="header-inner header-lg-height">
          <a
            href="/"
            onClick={handleToggleNav}
            className="btn-link toggle-sidebar d-lg-none header-icon sm-p-l-0 btn-icon-link"
            data-toggle="sidebar"
          >
            <i className="pg-icon">menu</i>
          </a>
          <div className="d-flex align-items-center w-100">
            <div className="">
              <Link
                to="/"
                aria-label={t("Kembali ke beranda")}
                className="brand d-lg-none ml-2"
              >
                <LogoText />
              </Link>
              <ul className="d-lg-inline-block d-none notification-list no-margin b-grey b-l b-r no-style p-l-0 p-r-20">
                <li className="p-r-10 inline">
                  <Link
                    to="/"
                    aria-label={t("Kembali ke beranda")}
                    className="brand inline align-self-end fs-16 font-montserrat"
                  >
                    <LogoText />
                  </Link>
                </li>
              </ul>
            </div>

            <Menu />
            <div className="ml-auto"></div>
            <div className={`border-right mr-3 ` + (isLogin ? `pr-2` : `pr-3`)}>
              {isLogin && (
                <>
                  <div
                    className={
                      'dropdown d-lg-block m-b-0 ' + (isOpen ? 'show' : '')
                    }
                  >
                    <button
                      className="profile-dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                      aria-label="profile dropdown"
                      onClick={() => setIsOpen((open) => !open)}
                    >
                      <div className="thumbnail-wrapper d32 circular b-white">
                        <div className="bg-info text-center text-white">
                          <span>{me.full_name[0]}</span>
                        </div>
                      </div>
                    </button>
                    <div
                      className={
                        'dropdown-menu dropdown-menu-right profile-dropdown ' +
                        (isOpen ? 'show' : '')
                      }
                      role="menu"
                    >
                      <div className="dropdown-item">
                        <span>
                          {t("Masuk sebagai")} <br />
                          <b>{me.full_name}</b>
                        </span>
                      </div>
                      <div className="dropdown-divider"></div>
                      <Link to="/profile" className="dropdown-item">
                        {t("Profil Saya")}
                      </Link>
                      <div className="dropdown-divider"></div>
                      <a
                        href="/"
                        onClick={handleLogout}
                        className="dropdown-item"
                      >
                        {t("Keluar")}
                      </a>
                    </div>
                  </div>
                </>
              )}
              {!isLogin && (
                <div className="fs-14 font-heading d-lg-block">
                  <button
                    onClick={handleToggleLogin}
                    aria-label="Klik untuk login"
                    className="btn text-white btn-info btn-sm fs-13"
                  >
                    <svg
                      aria-label="Klik untuk login"
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon-svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
                      <path d="M20 12h-13l3 -3m0 6l-3 -3" />
                    </svg>
                    {t("Masuk")}
                  </button>
                </div>
              )}
            </div>
            <div>
              <LanguageSwitcher />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Header)
