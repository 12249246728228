import queryString from 'query-string'

export const lfilter = {
  state: {
    history: null,
    filter: {},
    page: null,
  },

  _getFilters() {
    const { history } = this.state
    let filters = []
    let queries = Object.entries(queryString.parse(history.location.search))
    for (var x = 0; x < queries.length; x++) {
      filters.push({
        key: queries[x][0],
        value: queries[x][1],
      })
    }
    return filters
  },

  _addFilter() {
    const { filter } = this.state

    let filters = this._getFilters()

    if (filter.value) {
      if (filters.length === 0) {
        filters.push(filter)
      } else {
        let index = filters.findIndex((f) => f.key === filter.key)
        if (index >= 0) {
          filters[index].value = filter.value
        } else {
          filters.push(filter)
        }
      }
    }

    return filters
  },

  redirect({ history, page, filter, chart }) {
    this.state = {
      history,
      page,
      filter,
    }
    let filters = Array.isArray(filter) ? filter : this._addFilter()
    let params = filters.map((f) => `${f.key}=${f.value}`)
    let url = `/data/${page}/${chart}?${encodeURI(params.join('&'))}`
    if (page === 'file') {
      url = `/putusan?${encodeURI(params.join('&'))}`
    } else if (page === 'unduh') {
      url = `/unduh?${encodeURI(params.join('&'))}`
    }

    history.push(url)
  },
}
