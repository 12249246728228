export const reportConstants = {
  REPORT_GETALL_REQUEST: 'REPORT_GETALL_REQUEST',
  REPORT_GETALL_SUCCESS: 'REPORT_GETALL_SUCCESS',
  REPORT_GETALL_FAILURE: 'REPORT_GETALL_FAILURE',

  REPORT_GET_REQUEST: 'REPORT_GET_REQUEST',
  REPORT_GET_SUCCESS: 'REPORT_GET_SUCCESS',
  REPORT_GET_FAILURE: 'REPORT_GET_FAILURE',

  REPORT_UPDATE_REQUEST: 'REPORT_UPDATE_REQUEST',
  REPORT_UPDATE_SUCCESS: 'REPORT_UPDATE_SUCCESS',
  REPORT_UPDATE_FAILURE: 'REPORT_UPDATE_FAILURE',
}
