import React, { useEffect, useState } from 'react'
import { ErrorMessage } from './ErrorMessage'
import { Controller } from 'react-hook-form'
import DropzoneComponent from 'react-dropzone-component'
import 'public/assets/plugins/dropzone/css/dropzone.css'
import pdfIcon from 'assets/img/pdf.png'

export const FileUpload = React.memo(
  ({
    name,
    label,
    control,
    errors,
    rules = {},
    isMultiple = false,
    fileTypes,
    preview,
    watch,
  }) => {
    let [dropzone, setDropzone] = useState(null)
    let value = watch(name)

    useEffect(() => {
      if (dropzone && typeof value === 'string') {
        dropzone.removeAllFiles(true)
      }
    }, [value, dropzone])

    const thumb = {
      position: 'relative',
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      marginBottom: 8,
      marginRight: 8,
      width: 150,
      height: 150,
      padding: 4,
      boxSizing: 'border-box',
    }

    const thumbInner = {
      display: 'flex',
      minWidth: 0,
      overflow: 'hidden',
    }

    const img = {
      display: 'block',
      width: 'auto',
      height: '100%',
    }

    const thumbButton = {
      position: 'absolute',
      right: 10,
      bottom: 10,
      background: 'rgba(0,0,0,.8)',
      color: '#fff',
      border: 0,
      borderRadius: '.325em',
      cursor: 'pointer',
    }

    const thumbLabel = {
      position: 'absolute',
      right: 0,
      bottom: 10,
      background: 'rgba(0,0,0,.8)',
      color: '#fff',
      border: 0,
      borderRadius: '.325em',
      cursor: 'pointer',
    }

    return (
      <>
        <div
          className={
            'form-group form-group-default' +
            (rules.required ? ' required ' : '') +
            (errors[name] ? ' has-error ' : '')
          }
          style={{ overflow: 'inherit' }}
        >
          <label>{label}</label>
          <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ onChange, value }) => (
              <DropzoneComponent
                config={{
                  showFiletypeIcon: true,
                  postUrl: '/no-url',
                }}
                eventHandlers={{
                  init: (dz) => setDropzone(dz),
                  addedfile: (file) => {
                    // dropzone.removeAllFiles(true)
                    const reader = new FileReader()
                    reader.onload = function () {
                      var dataURL = reader.result

                      var arr = dataURL.split(','),
                        mime = arr[0].match(/:(.*?);/)[1],
                        bstr = atob(arr[1]),
                        n = bstr.length,
                        u8arr = new Uint8Array(n)
                      while (n--) {
                        u8arr[n] = bstr.charCodeAt(n)
                      }
                      var newFile = new File([u8arr], file.name, {
                        type: mime,
                      })
                      onChange([newFile])
                    }
                    reader.readAsDataURL(file)

                    // onChange([file])
                  },
                }}
                djsConfig={{
                  maxFiles: isMultiple ? isMultiple : 1,
                  autoProcessQueue: false,
                  addRemoveLinks: true,
                  thumbnailWidth: 150,
                  uploadMultiple: false,
                  acceptedFiles: fileTypes,
                }}
              >
                <aside className="ml-5 mt-2">
                  {!Array.isArray(watch(name)) && preview && watch(preview) && (
                    <div style={thumb}>
                      <div style={thumbInner}>
                        {fileTypes.includes('image/*') && (
                          <img
                            src={watch(preview)}
                            style={img}
                            alt={watch(preview)}
                          />
                        )}
                        {!fileTypes.includes('image/*') && (
                          <img src={pdfIcon} style={img} alt={watch(preview)} />
                        )}
                      </div>
                      {fileTypes.includes('image/*') && (
                        <button style={thumbButton} type="button">
                          Uploaded
                        </button>
                      )}
                      {!fileTypes.includes('image/*') && (
                        <button
                          style={thumbLabel}
                          className="fs-10 p-0"
                          type="button"
                        >
                          {
                            watch(preview).split('/')[
                              watch(preview).split('/').length - 1
                            ]
                          }
                        </button>
                      )}
                    </div>
                  )}
                </aside>
              </DropzoneComponent>
            )}
          />
        </div>
        <ErrorMessage errors={errors} name={name} label={label} />
      </>
    )
  }
)
