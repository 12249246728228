import { navigationConstants } from '../constants'

export function navigation(state = {}, action) {
  switch (action.type) {
    // GET
    case navigationConstants.NAVIGATION_TOGGLE:
      return {
        open: action.payload.open,
      }
    default:
      return state
  }
}
