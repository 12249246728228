import { summaryConstants } from '../constants'
import { summaryService } from '../services'
export const summaryActions = {
  getEvent,
  getLastEducation,
  getLastJob,
  getGender,
  getAge,
  getMarker,
  getProvinsi,
  init,
}

function init(params = {}) {
  return (dispatch) => {
    dispatch(getEvent(params))
    dispatch(getProvinsi(params))
    dispatch(getLastEducation(params))
    dispatch(getLastJob(params))
    dispatch(getGender(params))
    dispatch(getAge(params))
    dispatch(getMarker(params))
  }
}

function getEvent(params = {}) {
  return (dispatch) => {
    dispatch({ type: summaryConstants.SUMMARY_GET_EVENT_REQUEST, params })
    summaryService.getEvent(params).then(
      (res) => {
        dispatch({ type: summaryConstants.SUMMARY_GET_EVENT_SUCCESS, res })
      },
      (error) =>
        dispatch({ type: summaryConstants.SUMMARY_GET_EVENT_FAILURE, error })
    )
  }
}

function getProvinsi(params = {}) {
  return (dispatch) => {
    dispatch({ type: summaryConstants.SUMMARY_GET_PROVINSI_REQUEST, params })
    summaryService.getProvinsi(params).then(
      (res) => {
        dispatch({ type: summaryConstants.SUMMARY_GET_PROVINSI_SUCCESS, res })
      },
      (error) =>
        dispatch({ type: summaryConstants.SUMMARY_GET_PROVINSI_FAILURE, error })
    )
  }
}

function getLastEducation(params = {}) {
  return (dispatch) => {
    dispatch({
      type: summaryConstants.SUMMARY_GET_LAST_EDUCATION_REQUEST,
      params,
    })
    summaryService.getLastEducation(params).then(
      (res) => {
        dispatch({
          type: summaryConstants.SUMMARY_GET_LAST_EDUCATION_SUCCESS,
          res,
        })
      },
      (error) =>
        dispatch({
          type: summaryConstants.SUMMARY_GET_LAST_EDUCATION_FAILURE,
          error,
        })
    )
  }
}

function getLastJob(params = {}) {
  return (dispatch) => {
    dispatch({ type: summaryConstants.SUMMARY_GET_LAST_JOB_REQUEST, params })
    summaryService.getLastJob(params).then(
      (res) => {
        dispatch({ type: summaryConstants.SUMMARY_GET_LAST_JOB_SUCCESS, res })
      },
      (error) =>
        dispatch({ type: summaryConstants.SUMMARY_GET_LAST_JOB_FAILURE, error })
    )
  }
}

function getGender(params = {}) {
  return (dispatch) => {
    dispatch({ type: summaryConstants.SUMMARY_GET_GENDER_REQUEST, params })
    summaryService.getGender(params).then(
      (res) => {
        dispatch({ type: summaryConstants.SUMMARY_GET_GENDER_SUCCESS, res })
      },
      (error) =>
        dispatch({ type: summaryConstants.SUMMARY_GET_GENDER_FAILURE, error })
    )
  }
}

function getAge(params = {}) {
  return (dispatch) => {
    dispatch({ type: summaryConstants.SUMMARY_GET_AGE_REQUEST, params })
    summaryService.getAge(params).then(
      (res) => {
        dispatch({ type: summaryConstants.SUMMARY_GET_AGE_SUCCESS, res })
      },
      (error) =>
        dispatch({ type: summaryConstants.SUMMARY_GET_AGE_FAILURE, error })
    )
  }
}

function getMarker(params = {}) {
  return (dispatch) => {
    dispatch({ type: summaryConstants.SUMMARY_GET_MARKER_REQUEST, params })
    summaryService.getMarker(params).then(
      (res) => {
        dispatch({ type: summaryConstants.SUMMARY_GET_MARKER_SUCCESS, res })
      },
      (error) =>
        dispatch({ type: summaryConstants.SUMMARY_GET_MARKER_FAILURE, error })
    )
  }
}
