import { logConstants } from '../constants'
import { defaultState } from '../state'

let logState = {
  ...defaultState
}

export function log(state = logState, action) {
  switch (action.type) {
    // GET ALL
    case logConstants.LOG_GETALL_REQUEST:
      return {
        ...state,
        multi: {
          ...state.multi,
          loading: true,
        },
      }

    case logConstants.LOG_GETALL_SUCCESS:
      return {
        ...state,
        multi: {
          list: action.res.data,
          meta: action.res.meta,
        },
      }

    case logConstants.LOG_GETALL_FAILURE:
      return {
        ...state,
        multi: {
          error: action.error,
          list: [],
          meta: { total: 0 },
        },
      }

    // GET
    case logConstants.LOG_GET_REQUEST:
      return {
        ...state,
        single: {
          ...state.single,
          loading: true,
        },
      }

    case logConstants.LOG_GET_SUCCESS:
      return {
        ...state,
        single: {
          data: action.res.data,
        },
      }

    case logConstants.LOG_GET_FAILURE:
      return {
        ...state,
        single: {
          error: action.error,
        },
      }

    default:
      return state
  }
}
