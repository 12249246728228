export const statisticConstants = {
  STATISTIC_SEND_REQUEST: 'STATISTIC_SEND_REQUEST',
  STATISTIC_SEND_SUCCESS: 'STATISTIC_SEND_SUCCESS',
  STATISTIC_SEND_FAILURE: 'STATISTIC_SEND_FAILURE',

  STATISTIC_USER_REQUEST: 'STATISTIC_USER_REQUEST',
  STATISTIC_USER_SUCCESS: 'STATISTIC_USER_SUCCESS',
  STATISTIC_USER_FAILURE: 'STATISTIC_USER_FAILURE',

  STATISTIC_SUMMARY_PAGE_REQUEST: 'STATISTIC_SUMMARY_PAGE_REQUEST',
  STATISTIC_SUMMARY_PAGE_SUCCESS: 'STATISTIC_SUMMARY_PAGE_SUCCESS',
  STATISTIC_SUMMARY_PAGE_FAILURE: 'STATISTIC_SUMMARY_PAGE_FAILURE',

  STATISTIC_TOP_PAGE_REQUEST: 'STATISTIC_TOP_PAGE_REQUEST',
  STATISTIC_TOP_PAGE_SUCCESS: 'STATISTIC_TOP_PAGE_SUCCESS',
  STATISTIC_TOP_PAGE_FAILURE: 'STATISTIC_TOP_PAGE_FAILURE',

  STATISTIC_VISITOR_REQUEST: 'STATISTIC_VISITOR_REQUEST',
  STATISTIC_VISITOR_SUCCESS: 'STATISTIC_VISITOR_SUCCESS',
  STATISTIC_VISITOR_FAILURE: 'STATISTIC_VISITOR_FAILURE',

  STATISTIC_COUNTRY_REQUEST: 'STATISTIC_COUNTRY_REQUEST',
  STATISTIC_COUNTRY_SUCCESS: 'STATISTIC_COUNTRY_SUCCESS',
  STATISTIC_COUNTRY_FAILURE: 'STATISTIC_COUNTRY_FAILURE',
}
