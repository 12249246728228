import { unduhConstants } from '../constants'
import { defaultState } from '../state'

let unduhState = {
  ...defaultState,
  download: {
    loading: false,
    data: null,
    error: null,
  },
}

export function unduh(state = unduhState, action) {
  switch (action.type) {
    // GET ALL
    case unduhConstants.UNDUH_GETALL_REQUEST:
      return {
        ...state,
        multi: {
          ...state.multi,
          loading: true,
        },
      }

    case unduhConstants.UNDUH_GETALL_SUCCESS:
      return {
        ...state,
        multi: {
          list: action.res.data,
          meta: action.res.meta,
        },
      }

    case unduhConstants.UNDUH_GETALL_FAILURE:
      return {
        ...state,
        multi: {
          error: action.error,
          list: [],
          meta: { total: 0 },
        },
      }

    // CREATE XLS
    case unduhConstants.UNDUH_CREATEXLS_REQUEST:
      return {
        ...state,
        download: {
          loading: true,
        },
      }

    case unduhConstants.UNDUH_CREATEXLS_SUCCESS:
      return {
        ...state,
        download: {
          result: action.res.data,
        },
      }

    case unduhConstants.UNDUH_CREATEXLS_FAILURE:
      return {
        ...state,
        download: {
          error: action.error,
        },
      }

    // CREATE XLS
    case unduhConstants.UNDUH_DOWNLOADXLS_REQUEST:
      return {
        ...state,
        download: {
          loading: true,
        },
      }

    case unduhConstants.UNDUH_DOWNLOADXLS_SUCCESS:
      return {
        ...state,
        download: {
          result: action.res.data,
        },
      }

    case unduhConstants.UNDUH_DOWNLOADXLS_FAILURE:
      return {
        ...state,
        download: {
          error: action.error,
        },
      }

    default:
      return state
  }
}
