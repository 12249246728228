import { textSpeechConstants } from '../constants'
import { chartService } from '../services'
export const textSpeechActions = {
  get,
  setTextSpeechMode,
}

function get(params = {}, callback) {
  return (dispatch) => {
    dispatch({ type: textSpeechConstants.TEXT_SPEECH_GET_REQUEST, params })
    chartService.get(params).then(
      (res) => {
        dispatch({ type: textSpeechConstants.TEXT_SPEECH_GET_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) => dispatch({ type: textSpeechConstants.TEXT_SPEECH_GET_FAILURE, error })
    )
  }
}

function setTextSpeechMode(type) {
  let payload = { type }

  localStorage.setItem('cds-text-speech-mode', type)

  return (dispatch) => {
    dispatch({ type: textSpeechConstants.TEXT_SPEECH_TYPE, payload })
  }
}
