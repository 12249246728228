import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './button.css'

const ToggleButton = ({ toggled, onClick }) => {
  const [isToggled, toggle] = useState(toggled)

  useEffect(() => {
    toggle(toggled)
  }, [toggled])

  return (
    <div className='container-toggle'>
      <span className='on'>ON</span>
      <span className='off'>OFF</span>
      <label className="toggle">
        <input
          type="checkbox"
          defaultChecked={isToggled}
          onClick={() => onClick()}
        />
        <span />
      </label>
    </div>
  )
}

export default ToggleButton
