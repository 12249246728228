import { default as axiosBase } from 'axios'
import { handleSuccess, handleError } from './response'
import { api } from 'config'

const ax = axiosBase.create({
  baseURL: api.url,
  timeout: 120000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

ax.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['x-access-token'] = token
    }

    config.auth = api.auth
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

ax.interceptors.response.use(handleSuccess, handleError)

export const axios = ax
