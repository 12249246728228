
import { faqConstants } from '../constants'
import { defaultState } from '../state'

let faqDisplayState = {
  ...defaultState,
  download: {
    loading: false,
    data: null,
    error: null,
  },
}

export function faqDisplay(state = faqDisplayState, action) {
  switch (action.type) {
    // GET ALL
    case faqConstants.FAQ_GET_DISPLAY_REQUEST:
      return {
        ...state,
        multi: {
          ...state.multi,
          loading: true,
        },
      }

    case faqConstants.FAQ_GET_DISPLAY_SUCCESS:
      return {
        ...state,
        multi: {
          list: action.res.data
        },
      }

    case faqConstants.FAQ_GET_DISPLAY_FAILURE:
      return {
        ...state,
        multi: {
          error: action.error,
          list: [],
          meta: { total: 0 },
        },
      }

    default:
      return state
  }
}
