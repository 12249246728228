import PropTypes from 'prop-types'
import ScrollToTop from 'react-scroll-up'
import AuthModal from '../auth'

// style
import 'public/assets/plugins/bootstrap/css/bootstrap.min.css'
import 'public/assets/plugins/font-awesome/css/font-awesome.css'
import 'public/pages/css/themes/light.css'

import 'assets/css/menu.css'
import 'assets/css/style.css'
import 'assets/css/style.override.css'

const Layout = ({ children }) => {
  return (
    <>
      {children}
      <AuthModal />
      <ScrollToTop
        showUnder={160}
        aria-label="Scroll to Top"
        style={{ bottom: 15, zIndex: 98 }}
      >
        <button
          className="btn btn-warning btn-float btn-scroll d-print-none btn-float-custom"
          type="button"
        >
          <i className="fa text-btn-float fa-arrow-up"></i>
        </button>
      </ScrollToTop>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
