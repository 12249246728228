import { axios } from 'utils'

export const summaryService = {
  getEvent,
  getProvinsi,
  getLastEducation,
  getLastJob,
  getGender,
  getAge,
  getMarker,
}

function getEvent(filters) {
  return axios.post(`/summary/event`, filters)
}

function getProvinsi(filters) {
  return axios.post(`/summary/provinsi`, filters)
}

function getLastEducation(filters) {
  return axios.post(`/summary/last-education`, filters)
}

function getLastJob(filters) {
  return axios.post(`/summary/last-job`, filters)
}

function getGender(filters) {
  return axios.post(`/summary/gender`, filters)
}

function getAge(filters) {
  return axios.post(`/summary/age`, filters)
}

function getMarker(filters) {
  return axios.post(`/summary/marker`, filters)
}
