import PropTypes from 'prop-types'
import { createContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { userActions } from 'redux/actions'
export const AuthContext = createContext()
export default function AuthContextProvider({ children }) {
  const dispatch = useDispatch()
  const [me, setMe] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const getMe = () => {
    dispatch(
      userActions.getMe((res) => {
        setMe(res?.data || null)
        setIsLoading(false)
      })
    )
  }

  const isLogin = () => {
    return me?.id ?? false
  }

  useEffect(() => {
    getMe()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        me,
        getMe,
        isLoading,
        isLogin: isLogin(),
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
