export const menus = [
  {
    label: 'Beranda',
    to: '/',
  },
  {
    label: 'Publikasi',
    to: '/publikasi',
  },
  {
    label: 'Berita',
    to: '/berita',
  },
  {
    label: 'Data',
    to: ['/data'],
    id: 'data',
    perform: 'data:visit',
    subs: [
      {
        label: 'Demografi',
        to: '/data/demografi',
      },
      {
        label: 'Karakteristik Jaringan',
        to: '/data/karakteristik-jaringan',
      },
      {
        label: 'Pola Pemidanaan',
        to: '/data/pola-pemidanaan',
      },
      {
        label: 'Peta',
        to: '/data/peta',
      },
      {
        label: 'Tren Variabel',
        to: '/data/tren-variabel',
      },
    ],
  },
  {
    label: 'File Putusan',
    to: '/putusan',
  },
  {
    label: 'Unduh',
    to: '/unduh',
  },
  {
    label: 'Data Master',
    perform: 'master:visit',
    id: 'master',
    to: ['/master'],
    subs: [
      {
        label: 'File Putusan',
        perform: 'master/putusan:visit',
        to: '/master/putusan',
      },
      {
        label: 'Returnee',
        perform: 'master/returnee:visit',
        to: '/master/returnee',
      },
      {
        label: 'Publikasi',
        perform: 'master/publikasi:visit',
        to: '/master/publikasi',
      },
      {
        label: 'Berita',
        perform: 'master/news:visit',
        to: '/master/berita',
      },
      {
        label: 'Pengguna',
        perform: 'master/pengguna:visit',
        to: '/master/pengguna',
      },
      {
        label: 'Role',
        perform: 'master/role:visit',
        to: '/master/role',
      },
      {
        label: 'Laporan',
        perform: 'master/laporan:visit',
        to: '/master/laporan',
      },
      {
        label: 'FAQ',
        perform: 'master/faq:visit',
        to: '/master/faq',
      },
      {
        label: 'Konfigurasi',
        perform: 'master/konfigurasi:visit',
        to: '/master/konfigurasi',
      },
      {
        label: 'Log Aktivitas',
        perform: 'master/log:visit',
        to: '/master/log',
      },
    ],
  },
]
