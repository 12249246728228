import { statisticConstants } from '../constants'
import { statisticService } from '../services'
export const statisticActions = {
  send,
  getUserStatistic,
  getSummary,
  getTopPage,
  getVisitorStatistic,
  getCountryStatistic,
}

function send(name, detail = null, type = 'page') {
  return (dispatch) => {
    dispatch({ type: statisticConstants.STATISTIC_SEND_REQUEST })
    statisticService.send({ name, type, detail }).then(
      (res) => {
        dispatch({ type: statisticConstants.STATISTIC_SEND_SUCCESS, res })
      },
      (error) => {
        dispatch({ type: statisticConstants.STATISTIC_SEND_FAILURE, error })
      }
    )
  }
}

function getUserStatistic(params = {}) {
  return (dispatch) => {
    dispatch({
      type: statisticConstants.STATISTIC_USER_REQUEST,
      params,
    })
    statisticService.getUserStatistic(params).then(
      (res) => {
        dispatch({
          type: statisticConstants.STATISTIC_USER_SUCCESS,
          res,
        })
      },
      (error) =>
        dispatch({
          type: statisticConstants.STATISTIC_USER_FAILURE,
          error,
        })
    )
  }
}

function getSummary(params = {}) {
  return (dispatch) => {
    dispatch({
      type: statisticConstants.STATISTIC_SUMMARY_PAGE_REQUEST,
      params,
    })
    statisticService.getSummary(params).then(
      (res) => {
        dispatch({
          type: statisticConstants.STATISTIC_SUMMARY_PAGE_SUCCESS,
          res,
        })
      },
      (error) =>
        dispatch({
          type: statisticConstants.STATISTIC_SUMMARY_PAGE_FAILURE,
          error,
        })
    )
  }
}

function getTopPage(params = {}) {
  return (dispatch) => {
    dispatch({
      type: statisticConstants.STATISTIC_TOP_PAGE_REQUEST,
      params,
    })
    statisticService.getTopPage(params).then(
      (res) => {
        dispatch({
          type: statisticConstants.STATISTIC_TOP_PAGE_SUCCESS,
          res,
        })
      },
      (error) =>
        dispatch({
          type: statisticConstants.STATISTIC_TOP_PAGE_FAILURE,
          error,
        })
    )
  }
}

function getVisitorStatistic(params = {}) {
  return (dispatch) => {
    dispatch({
      type: statisticConstants.STATISTIC_VISITOR_REQUEST,
      params,
    })
    statisticService.getVisitorStatistic(params).then(
      (res) => {
        dispatch({
          type: statisticConstants.STATISTIC_VISITOR_SUCCESS,
          res,
        })
      },
      (error) =>
        dispatch({
          type: statisticConstants.STATISTIC_VISITOR_FAILURE,
          error,
        })
    )
  }
}

function getCountryStatistic(params = {}) {
  return (dispatch) => {
    dispatch({
      type: statisticConstants.STATISTIC_COUNTRY_REQUEST,
      params,
    })
    statisticService.getCountryStatistic(params).then(
      (res) => {
        dispatch({
          type: statisticConstants.STATISTIC_COUNTRY_SUCCESS,
          res,
        })
      },
      (error) =>
        dispatch({
          type: statisticConstants.STATISTIC_COUNTRY_FAILURE,
          error,
        })
    )
  }
}
