import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { chartActions, navigationActions } from 'redux/actions'
import { menus } from 'config'
import Can from '../../auth/Can'
import { useDispatch, useSelector } from 'react-redux'

function Sidebar() {
  const dispatch = useDispatch()
  const location = useLocation()
  const [route] = useState(location.pathname)
  const [openedSubmenu, setOpenedSubmenu] = useState([])
  const navigation = useSelector((state) => state.navigation)
  const { t } = useTranslation()

  useEffect(() => {
    if (route) {
      const parent = route.split('/')[1]
      addSubOpen(`/${parent}`)
    }
  }, [route])

  const handleToggle = () => {
    dispatch(navigationActions.toggle(false))
  }

  const handleToggleSub = (e, sub) => {
    e.preventDefault()
    addSubOpen(sub)
  }

  const addSubOpen = (sub) => {
    setOpenedSubmenu((openedSubmenu) => {
      let idx = openedSubmenu.findIndex((s) => s === sub)
      if (idx >= 0) {
        openedSubmenu.splice(idx, 1)
      } else {
        openedSubmenu.push(sub)
      }
      return [...openedSubmenu]
    })
  }

  const chart = useSelector((state) => state.chart)

  const handleDisplayMode = (type) => {
    dispatch(chartActions.setDisplayMode(type))
  }

  return (
    <nav
      className={'d-lg-none page-sidebar ' + (navigation.open ? 'open' : '')}
    >
      <div className="sidebar-overlay-slide from-top" id="appMenu"></div>
      <div className="sidebar-menu">
        <ul className="menu-items">
          {menus.map((menu, index) => {
            if (menu.subs) {
              let menuClass = ''
              let arrowClass = ''
              let submenuStyle = { display: 'none' }

              if (openedSubmenu.find((s) => s === menu.to)) {
                menuClass = 'active'
                arrowClass = 'open active'
                submenuStyle = {
                  display: 'block',
                }
              }

              const Menu = (
                <li className={menuClass} key={index}>
                  <a href="/" onClick={(e) => handleToggleSub(e, menu.to)}>
                    <span className="title">{t(menu.label)}</span>
                    <span className={`ml-1 arrow ${arrowClass}`}></span>
                  </a>
                  <ul className="sub-menu" style={submenuStyle}>
                    {menu.subs.map((sub) => {
                      const Submenu = (
                        <li
                          className={route.startsWith(sub.to) ? 'active' : ''}
                          key={sub.to}
                        >
                          <Link onClick={handleToggle} to={sub.to}>
                            <span className="">{t(sub.label)}</span>
                          </Link>
                        </li>
                      )

                      // check permission
                      if (sub.perform) {
                        return (
                          <Can
                            key={sub.to}
                            perform={sub.perform}
                            yes={() => Submenu}
                            no={() => <></>}
                          />
                        )
                      } else {
                        return Submenu
                      }
                    })}
                  </ul>
                </li>
              )

              // check permission
              if (menu.perform) {
                return (
                  <Can
                    key={index}
                    perform={menu.perform}
                    yes={() => Menu}
                    no={() => <></>}
                  />
                )
              } else {
                return Menu
              }
            } else {
              const Menu = (
                <li
                  className={
                    menu.to === '/'
                      ? route === menu.to
                        ? 'active'
                        : ''
                      : route.startsWith(menu.to)
                      ? 'active'
                      : ''
                  }
                  key={index}
                >
                  <Link onClick={handleToggle} to={menu.to} className="">
                    <span className="">{t(menu.label)}</span>
                  </Link>
                </li>
              )

              // check permission
              if (menu.perform) {
                return (
                  <Can
                    key={index}
                    perform={menu.perform}
                    yes={() => Menu}
                    no={() => <></>}
                  />
                )
              } else {
                return Menu
              }
            }
          })}

          {/* <li>
            {location.pathname === '/' && (
              <div className="">
                <div className="ml-3 pl-1 mt-2 fs-14 font-heading">
                  {(() => {
                    switch (chart.type) {
                      case 'grafik':
                        return (
                          <button
                            onClick={() => handleDisplayMode('tabel')}
                            aria-label="Tampilkan bentuk tabel"
                            className="btn btn-sm fs-13"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon mr-1"
                              style={{ width: '15px' }}
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <rect x="4" y="4" width="16" height="16" rx="2" />
                              <line x1="4" y1="12" x2="20" y2="12" />
                              <line x1="12" y1="4" x2="12" y2="20" />
                            </svg>
                            Mode Tabel
                          </button>
                        )
                      case 'tabel':
                        return (
                          <button
                            onClick={() => handleDisplayMode('grafik')}
                            aria-label="Tampilkan bentuk tabel"
                            className="btn btn-sm fs-13"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon mr-1"
                              style={{ width: '15px' }}
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <rect x="3" y="12" width="6" height="8" rx="1" />
                              <rect x="9" y="8" width="6" height="12" rx="1" />
                              <rect x="15" y="4" width="6" height="16" rx="1" />
                              <line x1="4" y1="20" x2="18" y2="20" />
                            </svg>
                            Mode Grafik
                          </button>
                        )
                      

                      default:
                        return null
                    }
                  })()}
                </div>
              </div>
            )}
          </li> */}
        </ul>
        <div className="clearfix"></div>
      </div>
    </nav>
  )
}
export default React.memo(Sidebar)
