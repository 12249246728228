import { reportConstants } from '../constants'
import { defaultState } from '../state'

let reportState = {
  ...defaultState,
  download: {
    loading: false,
    data: null,
    error: null,
  },
}

export function report(state = reportState, action) {
  switch (action.type) {
    // GET ALL
    case reportConstants.REPORT_GETALL_REQUEST:
      return {
        ...state,
        multi: {
          ...state.multi,
          loading: true,
        },
      }

    case reportConstants.REPORT_GETALL_SUCCESS:
      return {
        ...state,
        multi: {
          list: action.res.data,
          meta: action.res.meta,
        },
      }

    case reportConstants.REPORT_GETALL_FAILURE:
      return {
        ...state,
        multi: {
          error: action.error,
          list: [],
          meta: { total: 0 },
        },
      }

    // GET
    case reportConstants.REPORT_GET_REQUEST:
      return {
        ...state,
        single: {
          ...state.single,
          loading: true,
        },
      }

    case reportConstants.REPORT_GET_SUCCESS:
      return {
        ...state,
        single: {
          data: action.res.data,
        },
      }

    case reportConstants.REPORT_GET_FAILURE:
      return {
        ...state,
        single: {
          error: action.error,
        },
      }

    // UPDATE
    case reportConstants.REPORT_UPDATE_REQUEST:
      return {
        ...state,
        update: {
          loading: true,
        },
      }

    case reportConstants.REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          result: action.res.data,
        },
      }

    case reportConstants.REPORT_UPDATE_FAILURE:
      return {
        ...state,
        update: {
          error: action.error,
        },
      }

    default:
      return state
  }
}
