import React from 'react'
import { ErrorMessage } from './ErrorMessage'
import { Controller } from 'react-hook-form'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export const Editor = React.memo(
  ({
    name,
    label,
    control,
    defaultValue = '',
    errors,
    rules = {},
    style = {},
    toolbar = [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
      {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'video'],
      ['clean']
    ],
  }) => {
    return (
      <>
        <div
          className={
            'form-group' +
            (rules.required ? ' required ' : '') +
            (errors[name] ? ' has-error ' : '')
          }
          style={{ overflow: 'inherit' }}
        >
          <label>{label}</label>
          <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ onChange, value }) => (
              <ReactQuill
                className="my-0 editor"
                style={style}
                key={name}
                value={value || ''}
                onChange={(desc) => {
                  onChange(desc)
                }}
                modules={{
                  toolbar,
                }}
                formats={[
                  'header', 'font', 'size',
                  'bold', 'italic', 'underline', 'strike', 'blockquote',
                  'list', 'bullet', 'indent',
                  'link', 'image', 'video'
                ]}
              />
            )}
          />
        </div>
        <ErrorMessage errors={errors} name={name} label={label} />
      </>
    )
  }
)
