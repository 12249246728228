import { putusanDraftConstants } from '../constants'
import { defaultState } from '../state'

let putusanState = {
  ...defaultState,
  details: {
    loading: false,
    data: null,
    error: null,
  },
  ringkasan: {
    loading: false,
    data: null,
    error: null,
  },
  verify: {
    ...defaultState.create,
  },
  download: {
    loading: false,
    data: null,
    error: null,
  },
  upload: {
    loading: false,
    data: null,
    error: null,
  },
  delete_file: {
    loading: false,
    result: null,
    error: null,
  },
}

export function putusan_draft(state = putusanState, action) {
  switch (action.type) {
    // GET ALL
    case putusanDraftConstants.PUTUSAN_DRAFT_GETALL_REQUEST:
      return {
        ...state,
        multi: {
          ...state.multi,
          loading: true,
        },
      }

    case putusanDraftConstants.PUTUSAN_DRAFT_GETALL_SUCCESS:
      return {
        ...state,
        multi: {
          list: action.res.data,
          meta: action.res.meta,
        },
      }

    case putusanDraftConstants.PUTUSAN_DRAFT_GETALL_FAILURE:
      return {
        ...state,
        multi: {
          error: action.error,
          list: [],
          meta: { total: 0 },
        },
      }

    // GET
    case putusanDraftConstants.PUTUSAN_DRAFT_GET_REQUEST:
      return {
        ...state,
        single: {
          ...state.single,
          loading: true,
        },
      }

    case putusanDraftConstants.PUTUSAN_DRAFT_GET_SUCCESS:
      return {
        ...state,
        single: {
          data: action.res.data,
        },
      }

    case putusanDraftConstants.PUTUSAN_DRAFT_GET_FAILURE:
      return {
        ...state,
        single: {
          error: action.error,
        },
      }

    // RINGKASAN
    case putusanDraftConstants.PUTUSAN_DRAFT_RINGKASAN_REQUEST:
      return {
        ...state,
        ringkasan: {
          ...state.ringkasan,
          loading: true,
        },
      }

    case putusanDraftConstants.PUTUSAN_DRAFT_RINGKASAN_SUCCESS:
      return {
        ...state,
        ringkasan: {
          data: action.res.data,
        },
      }

    case putusanDraftConstants.PUTUSAN_DRAFT_RINGKASAN_FAILURE:
      return {
        ...state,
        ringkasan: {
          error: action.error,
        },
      }

    // GET DETAILS
    case putusanDraftConstants.PUTUSAN_DRAFT_DETAILS_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          loading: true,
        },
      }

    case putusanDraftConstants.PUTUSAN_DRAFT_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          data: action.res.data,
        },
      }

    case putusanDraftConstants.PUTUSAN_DRAFT_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          error: action.error,
        },
      }

    // CREATE
    case putusanDraftConstants.PUTUSAN_DRAFT_CREATE_REQUEST:
      return {
        ...state,
        create: {
          loading: true,
        },
      }
    case putusanDraftConstants.PUTUSAN_DRAFT_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          result: action.res.data,
        },
      }
    case putusanDraftConstants.PUTUSAN_DRAFT_CREATE_FAILURE:
      return {
        ...state,
        create: {
          error: action.error,
        },
      }

    // UPDATE
    case putusanDraftConstants.PUTUSAN_DRAFT_UPDATE_REQUEST:
      return {
        ...state,
        update: {
          loading: true,
        },
      }

    case putusanDraftConstants.PUTUSAN_DRAFT_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          result: action.res.data,
        },
      }

    case putusanDraftConstants.PUTUSAN_DRAFT_UPDATE_FAILURE:
      return {
        ...state,
        update: {
          error: action.error,
        },
      }

    // VERIFY
    case putusanDraftConstants.PUTUSAN_DRAFT_VERIFY_REQUEST:
      return {
        ...state,
        verify: {
          loading: true,
        },
      }

    case putusanDraftConstants.PUTUSAN_DRAFT_VERIFY_SUCCESS:
      return {
        ...state,
        verify: {
          result: action.res.data,
        },
      }
    case putusanDraftConstants.PUTUSAN_DRAFT_VERIFY_FAILURE:
      return {
        ...state,
        verify: {
          error: action.error,
        },
      }

    // DELETE
    case putusanDraftConstants.PUTUSAN_DRAFT_DELETE_REQUEST:
      return {
        ...state,
        delete: {
          loading: true,
        },
      }

    case putusanDraftConstants.PUTUSAN_DRAFT_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          result: action.res.data,
        },
      }
    case putusanDraftConstants.PUTUSAN_DRAFT_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          error: action.error,
        },
      }

    // DELETE FILE
    case putusanDraftConstants.PUTUSAN_DRAFT_FILE_DELETE_REQUEST:
      return {
        ...state,
        delete_file: {
          loading: true,
        },
      }

    case putusanDraftConstants.PUTUSAN_DRAFT_FILE_DELETE_SUCCESS:
      return {
        ...state,
        delete_file: {
          result: action.res.data,
        },
      }
    case putusanDraftConstants.PUTUSAN_DRAFT_FILE_DELETE_FAILURE:
      return {
        ...state,
        delete_file: {
          error: action.error,
        },
      }

    // DOWNLOAD
    case putusanDraftConstants.PUTUSAN_DRAFT_DOWNLOAD_REQUEST:
      return {
        ...state,
        download: {
          loading: true,
        },
      }

    case putusanDraftConstants.PUTUSAN_DRAFT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        download: {
          result: action.res.data,
        },
      }
    case putusanDraftConstants.PUTUSAN_DRAFT_DOWNLOAD_FAILURE:
      return {
        ...state,
        download: {
          error: action.error,
        },
      }
    // UPLOAD
    case putusanDraftConstants.PUTUSAN_DRAFT_UPLOAD_REQUEST:
      return {
        ...state,
        upload: {
          loading: true,
        },
      }

    case putusanDraftConstants.PUTUSAN_DRAFT_UPLOAD_SUCCESS:
      return {
        ...state,
        upload: {
          result: action.res.data,
        },
      }
    case putusanDraftConstants.PUTUSAN_DRAFT_UPLOAD_FAILURE:
      return {
        ...state,
        upload: {
          error: action.error,
        },
      }

    default:
      return state
  }
}
