import { userConstants, appConstants } from '../constants'
import { userService } from '../services'
import { toastr } from 'react-redux-toastr'
export const userActions = {
  getMe,
  update,
}

function getMe(callback) {
  return (dispatch) => {
    dispatch({ type: userConstants.USER_GET_ME_REQUEST })
    userService.getMe().then(
      (res) => {
        dispatch({ type: userConstants.USER_GET_ME_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) => {
        dispatch({ type: userConstants.USER_GET_ME_FAILURE, error })
        if (callback) callback()
      }
    )
  }
}

function update(data, callback) {
  let payload = { data }
  return (dispatch) => {
    dispatch({ type: userConstants.USER_UPDATE_REQUEST, payload })
    userService.update(data).then(
      (res) => {
        dispatch({ type: userConstants.USER_UPDATE_SUCCESS, res })
        if (res.message)
          toastr.success(appConstants.NOTIF_TITLE_SUCCESS, res.message)
        if (callback) callback(res)
      },
      (error) => {
        dispatch({ type: userConstants.USER_UPDATE_FAILURE, error })
        if (callback) callback(error)
        toastr.error(
          appConstants.NOTIF_TITLE_ERROR,
          typeof error === 'object' ? 'Ada kesalahan input' : error
        )
      }
    )
  }
}
