import { searchConstants } from '../constants'
import { searchService } from '../services'
export const searchActions = {
  get,
  clear,
  getProvinsi,
  getKabkota,
  getSearchKabkota,
  getJaringan,
  getTahunPutusan,
  getUsiaKetikaDiputus,
  getHakim,
  getMotif,
  getWilayahPengadilan,
  getPerbuatan,
  getPasal,
  getPutusan,
  getPutusanBandingNama
}

function get(params = {}) {
  return (dispatch) => {
    dispatch({ type: searchConstants.SEARCH_GET_REQUEST, params })
    searchService.get(params).then(
      (res) => {
        dispatch({ type: searchConstants.SEARCH_GET_SUCCESS, res })
      },
      (error) => dispatch({ type: searchConstants.SEARCH_GET_FAILURE, error })
    )
  }
}

function clear(params = {}) {
  return (dispatch) => {
    dispatch({ type: searchConstants.SEARCH_CLEAR_SUCCESS, params })
  }
}

function getProvinsi(callback) {
  return (dispatch) => {
    dispatch({ type: searchConstants.SEARCH_PROVINSI_REQUEST, payload: {} })
    searchService.getProvinsi().then(
      (res) => {
        dispatch({ type: searchConstants.SEARCH_PROVINSI_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) =>
        dispatch({ type: searchConstants.SEARCH_PROVINSI_FAILURE, error })
    )
  }
}

function getKabkota(params, callback) {
  let payload = { params }
  return (dispatch) => {
    dispatch({ type: searchConstants.SEARCH_KABKOTA_REQUEST, payload })
    searchService.getKabkota(params).then(
      (res) => {
        dispatch({ type: searchConstants.SEARCH_KABKOTA_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) =>
        dispatch({ type: searchConstants.SEARCH_KABKOTA_FAILURE, error })
    )
  }
}

function getSearchKabkota(params, callback) {
  return () => {
    searchService.getSearchKabkota(params).then((res) => {
      if (callback) callback(res)
    })
  }
}

function getJaringan(callback) {
  return (dispatch) => {
    dispatch({ type: searchConstants.SEARCH_JARINGAN_REQUEST, payload: {} })
    searchService.getJaringan().then(
      (res) => {
        dispatch({ type: searchConstants.SEARCH_JARINGAN_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) =>
        dispatch({ type: searchConstants.SEARCH_JARINGAN_FAILURE, error })
    )
  }
}

function getTahunPutusan(callback) {
  return (dispatch) => {
    dispatch({ type: searchConstants.SEARCH_TAHUN_REQUEST, payload: {} })
    searchService.getTahunPutusan().then(
      (res) => {
        dispatch({ type: searchConstants.SEARCH_TAHUN_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) => dispatch({ type: searchConstants.SEARCH_TAHUN_FAILURE, error })
    )
  }
}

function getHakim(callback) {
  return (dispatch) => {
    dispatch({ type: searchConstants.SEARCH_HAKIM_REQUEST, payload: {} })
    searchService.getHakim().then(
      (res) => {
        dispatch({ type: searchConstants.SEARCH_HAKIM_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) => dispatch({ type: searchConstants.SEARCH_HAKIM_FAILURE, error })
    )
  }
}

function getMotif(callback) {
  return (dispatch) => {
    dispatch({ type: searchConstants.SEARCH_MOTIF_REQUEST, payload: {} })
    searchService.getMotif().then(
      (res) => {
        dispatch({ type: searchConstants.SEARCH_MOTIF_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) => dispatch({ type: searchConstants.SEARCH_MOTIF_FAILURE, error })
    )
  }
}

function getWilayahPengadilan(callback) {
  return (dispatch) => {
    dispatch({
      type: searchConstants.SEARCH_WILAYAH_PENGADILAN_REQUEST,
      payload: {},
    })
    searchService.getWilayahPengadilan().then(
      (res) => {
        dispatch({
          type: searchConstants.SEARCH_WILAYAH_PENGADILAN_SUCCESS,
          res,
        })
        if (callback) callback(res)
      },
      (error) =>
        dispatch({
          type: searchConstants.SEARCH_WILAYAH_PENGADILAN_FAILURE,
          error,
        })
    )
  }
}

function getUsiaKetikaDiputus() {
  return [
    { value: '*-20.0', label: '0-20 Tahun' },
    { value: '21.0-30.0', label: '21-30 Tahun' },
    { value: '31.0-40.0', label: '31-40 Tahun' },
    { value: '41.0-50.0', label: '41-50 Tahun' },
    { value: '51.0-*', label: '21-30 Tahun' },

    // {value: '0-20 Tahun', label: '0-20 Tahun'},
    // {value: '21-30 Tahun', label: '21-30 Tahun'},
    // {value: '31-40 Tahun', label: '31-40 Tahun'},
    // {value: '41-50 Tahun', label: '41-50 Tahun'},
    // {value: '21-30 Tahun', label: '21-30 Tahun'}
  ]
}

function getPerbuatan(callback) {
  return (dispatch) => {
    dispatch({ type: searchConstants.SEARCH_PERBUATAN_REQUEST, payload: {} })
    searchService.getPerbuatan().then(
      (res) => {
        dispatch({ type: searchConstants.SEARCH_PERBUATAN_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) =>
        dispatch({ type: searchConstants.SEARCH_PERBUATAN_FAILURE, error })
    )
  }
}

function getPasal(callback) {
  return (dispatch) => {
    dispatch({ type: searchConstants.SEARCH_PASAL_REQUEST, payload: {} })
    searchService.getPasal().then(
      (res) => {
        dispatch({ type: searchConstants.SEARCH_PASAL_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) =>
        dispatch({ type: searchConstants.SEARCH_PASAL_FAILURE, error })
    )
  }
}

function getPutusan(payload, callback) {
  return (dispatch) => {
    dispatch({ type: searchConstants.SEARCH_PUTUSAN_REQUEST, payload })
    searchService.getPutusan(payload).then(
      (res) => {
        dispatch({ type: searchConstants.SEARCH_PUTUSAN_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) =>
        dispatch({ type: searchConstants.SEARCH_PUTUSAN_FAILURE, error })
    )
  }
}

function getPutusanBandingNama(payload, callback) {
  return (dispatch) => {
    dispatch({ type: searchConstants.SEARCH_PUTUSAN_REQUEST, payload })
    searchService.getPutusanBandingNama(payload).then(
      (res) => {
        dispatch({ type: searchConstants.SEARCH_PUTUSAN_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) =>
        dispatch({ type: searchConstants.SEARCH_PUTUSAN_FAILURE, error })
    )
  }
}