export const returneeConstants = {
  RETURNEE_GETALL_REQUEST: 'RETURNEE_GETALL_REQUEST',
  RETURNEE_GETALL_SUCCESS: 'RETURNEE_GETALL_SUCCESS',
  RETURNEE_GETALL_FAILURE: 'RETURNEE_GETALL_FAILURE',

  RETURNEE_GET_REQUEST: 'RETURNEE_GET_REQUEST',
  RETURNEE_GET_SUCCESS: 'RETURNEE_GET_SUCCESS',
  RETURNEE_GET_FAILURE: 'RETURNEE_GET_FAILURE',

  RETURNEE_CREATE_REQUEST: 'RETURNEE_CREATE_REQUEST',
  RETURNEE_CREATE_SUCCESS: 'RETURNEE_CREATE_SUCCESS',
  RETURNEE_CREATE_FAILURE: 'RETURNEE_CREATE_FAILURE',

  RETURNEE_UPDATE_REQUEST: 'RETURNEE_UPDATE_REQUEST',
  RETURNEE_UPDATE_SUCCESS: 'RETURNEE_UPDATE_SUCCESS',
  RETURNEE_UPDATE_FAILURE: 'RETURNEE_UPDATE_FAILURE',

  RETURNEE_DELETE_REQUEST: 'RETURNEE_DELETE_REQUEST',
  RETURNEE_DELETE_SUCCESS: 'RETURNEE_DELETE_SUCCESS',
  RETURNEE_DELETE_FAILURE: 'RETURNEE_DELETE_FAILURE',

  RETURNEE_VERIFY_REQUEST: 'RETURNEE_VERIFY_REQUEST',
  RETURNEE_VERIFY_SUCCESS: 'RETURNEE_VERIFY_SUCCESS',
  RETURNEE_VERIFY_FAILURE: 'RETURNEE_VERIFY_FAILURE',

  RETURNEE_DOWNLOAD_REQUEST: 'RETURNEE_DOWNLOAD_REQUEST',
  RETURNEE_DOWNLOAD_SUCCESS: 'RETURNEE_DOWNLOAD_SUCCESS',
  RETURNEE_DOWNLOAD_FAILURE: 'RETURNEE_DOWNLOAD_FAILURE',
}
