import { navigationConstants } from '../constants'
export const navigationActions = {
  toggle,
}

function toggle(open) {
  const body = document.querySelector('body')
  if (open) body.classList.add('sidebar-open')
  else body.classList.remove('sidebar-open')

  let payload = { open }
  return (dispatch) => {
    dispatch({ type: navigationConstants.NAVIGATION_TOGGLE, payload })
  }
}
