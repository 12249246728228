const process = {
  loading: false,
  result: null,
  error: null,
}

export const defaultState = {
  multi: {
    loading: false,
    list: [],
    meta: { total: 0 },
    error: null,
  },

  single: {
    loading: false,
    data: null,
    error: null,
  },

  create: process,
  update: process,
  delete: process,
}
