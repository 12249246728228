export const summaryConstants = {
  SUMMARY_GET_EVENT_REQUEST: 'SUMMARY_GET_EVENT_REQUEST',
  SUMMARY_GET_EVENT_SUCCESS: 'SUMMARY_GET_EVENT_SUCCESS',
  SUMMARY_GET_EVENT_FAILURE: 'SUMMARY_GET_EVENT_FAILURE',

  SUMMARY_GET_PROVINSI_REQUEST: 'SUMMARY_GET_PROVINSI_REQUEST',
  SUMMARY_GET_PROVINSI_SUCCESS: 'SUMMARY_GET_PROVINSI_SUCCESS',
  SUMMARY_GET_PROVINSI_FAILURE: 'SUMMARY_GET_PROVINSI_FAILURE',

  SUMMARY_GET_LAST_EDUCATION_REQUEST: 'SUMMARY_GET_LAST_EDUCATION_REQUEST',
  SUMMARY_GET_LAST_EDUCATION_SUCCESS: 'SUMMARY_GET_LAST_EDUCATION_SUCCESS',
  SUMMARY_GET_LAST_EDUCATION_FAILURE: 'SUMMARY_GET_LAST_EDUCATION_FAILURE',

  SUMMARY_GET_LAST_JOB_REQUEST: 'SUMMARY_GET_LAST_JOB_REQUEST',
  SUMMARY_GET_LAST_JOB_SUCCESS: 'SUMMARY_GET_LAST_JOB_SUCCESS',
  SUMMARY_GET_LAST_JOB_FAILURE: 'SUMMARY_GET_LAST_JOB_FAILURE',

  SUMMARY_GET_GENDER_REQUEST: 'SUMMARY_GET_GENDER_REQUEST',
  SUMMARY_GET_GENDER_SUCCESS: 'SUMMARY_GET_GENDER_SUCCESS',
  SUMMARY_GET_GENDER_FAILURE: 'SUMMARY_GET_GENDER_FAILURE',

  SUMMARY_GET_AGE_REQUEST: 'SUMMARY_GET_AGE_REQUEST',
  SUMMARY_GET_AGE_SUCCESS: 'SUMMARY_GET_AGE_SUCCESS',
  SUMMARY_GET_AGE_FAILURE: 'SUMMARY_GET_AGE_FAILURE',

  SUMMARY_GET_MARKER_REQUEST: 'SUMMARY_GET_MARKER_REQUEST',
  SUMMARY_GET_MARKER_SUCCESS: 'SUMMARY_GET_MARKER_SUCCESS',
  SUMMARY_GET_MARKER_FAILURE: 'SUMMARY_GET_MARKER_FAILURE',
}
