export const publicationConstants = {
  PUBLICATION_GETALL_REQUEST: 'PUBLICATION_GETALL_REQUEST',
  PUBLICATION_GETALL_SUCCESS: 'PUBLICATION_GETALL_SUCCESS',
  PUBLICATION_GETALL_FAILURE: 'PUBLICATION_GETALL_FAILURE',

  PUBLICATION_GET_REQUEST: 'PUBLICATION_GET_REQUEST',
  PUBLICATION_GET_SUCCESS: 'PUBLICATION_GET_SUCCESS',
  PUBLICATION_GET_FAILURE: 'PUBLICATION_GET_FAILURE',

  PUBLICATION_CREATE_REQUEST: 'PUBLICATION_CREATE_REQUEST',
  PUBLICATION_CREATE_SUCCESS: 'PUBLICATION_CREATE_SUCCESS',
  PUBLICATION_CREATE_FAILURE: 'PUBLICATION_CREATE_FAILURE',

  PUBLICATION_UPDATE_REQUEST: 'PUBLICATION_UPDATE_REQUEST',
  PUBLICATION_UPDATE_SUCCESS: 'PUBLICATION_UPDATE_SUCCESS',
  PUBLICATION_UPDATE_FAILURE: 'PUBLICATION_UPDATE_FAILURE',

  PUBLICATION_DELETE_REQUEST: 'PUBLICATION_DELETE_REQUEST',
  PUBLICATION_DELETE_SUCCESS: 'PUBLICATION_DELETE_SUCCESS',
  PUBLICATION_DELETE_FAILURE: 'PUBLICATION_DELETE_FAILURE',

  PUBLICATION_UPLOAD_REQUEST: 'PUBLICATION_UPLOAD_REQUEST',
  PUBLICATION_UPLOAD_SUCCESS: 'PUBLICATION_UPLOAD_SUCCESS',
  PUBLICATION_UPLOAD_FAILURE: 'PUBLICATION_UPLOAD_FAILURE',

  PUBLICATION_DOWNLOAD_REQUEST: 'PUBLICATION_DOWNLOAD_REQUEST',
  PUBLICATION_DOWNLOAD_SUCCESS: 'PUBLICATION_DOWNLOAD_SUCCESS',
  PUBLICATION_DOWNLOAD_FAILURE: 'PUBLICATION_DOWNLOAD_FAILURE',
}
