import { putusanConstants, appConstants } from '../constants'
import { putusanService } from '../services'
import { toastr } from 'react-redux-toastr'
export const putusanActions = {
  getAll,
  get,
  download,
  report
}

function getAll(params = {}) {
  return (dispatch) => {
    dispatch({ type: putusanConstants.PUTUSAN_GETALL_REQUEST, params })
    putusanService.getAll(params).then(
      (res) => {
        dispatch({ type: putusanConstants.PUTUSAN_GETALL_SUCCESS, res })
      },
      (error) =>
        dispatch({ type: putusanConstants.PUTUSAN_GETALL_FAILURE, error })
    )
  }
}

function get(param, callback) {
  let request = putusanConstants.PUTUSAN_GET_REQUEST
  let success = putusanConstants.PUTUSAN_GET_SUCCESS
  let failure = putusanConstants.PUTUSAN_GET_FAILURE

  if (param.type === 'detail') {
    request = putusanConstants.PUTUSAN_DETAILS_REQUEST
    success = putusanConstants.PUTUSAN_DETAILS_SUCCESS
    failure = putusanConstants.PUTUSAN_DETAILS_FAILURE
  } else if (param.type === 'ringkasan') {
    request = putusanConstants.PUTUSAN_RINGKASAN_REQUEST
    success = putusanConstants.PUTUSAN_RINGKASAN_SUCCESS
    failure = putusanConstants.PUTUSAN_RINGKASAN_FAILURE
  }

  return (dispatch) => {
    dispatch({ type: request, param })
    putusanService.get(param).then(
      (res) => {
        dispatch({ type: success, res })
        if (callback) callback(res)
      },
      (error) => {
        dispatch({ type: failure, error })
        if (error) toastr.error(appConstants.NOTIF_TITLE_ERROR, error)
      }
    )
  }
}

function download(param, callback) {
  return (dispatch) => {
    dispatch({ type: putusanConstants.PUTUSAN_DOWNLOAD_REQUEST, param })
    putusanService.download(param).then(
      (res) => {
        dispatch({ type: putusanConstants.PUTUSAN_DOWNLOAD_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) => {
        dispatch({ type: putusanConstants.PUTUSAN_DOWNLOAD_FAILURE, error })
        if (error) toastr.error(appConstants.NOTIF_TITLE_ERROR, error)
      }
    )
  }
}

function report(param, callback) {
  return (dispatch) => {
    dispatch({ type: putusanConstants.PUTUSAN_REPORT_REQUEST, param })
    putusanService.report(param).then(
      (res) => {
        dispatch({ type: putusanConstants.PUTUSAN_REPORT_SUCCESS, res })
        if (res.message)
          toastr.success(appConstants.NOTIF_TITLE_SUCCESS, res.message)
        if (callback) callback(res)
      },
      (error) => {
        dispatch({ type: putusanConstants.PUTUSAN_REPORT_FAILURE, error })
        if (error) toastr.error(appConstants.NOTIF_TITLE_ERROR, error)
      }
    )
  }
}
