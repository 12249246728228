import { unduhConstants } from '../constants'
import { unduhService } from '../services'
export const unduhActions = {
  getAll,
  download,
}

function getAll(params = {}) {
  return (dispatch) => {
    dispatch({ type: unduhConstants.UNDUH_GETALL_REQUEST, params })
    unduhService.getAll(params).then(
      (res) => {
        dispatch({ type: unduhConstants.UNDUH_GETALL_SUCCESS, res })
      },
      (error) => dispatch({ type: unduhConstants.UNDUH_GETALL_FAILURE, error })
    )
  }
}

function download(params = {}, callback) {
  return (dispatch) => {
    dispatch({ type: unduhConstants.UNDUH_CREATEXLS_REQUEST, params })
    unduhService.download(params).then(
      (res) => {
        dispatch({ type: unduhConstants.UNDUH_CREATEXLS_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) =>
        dispatch({ type: unduhConstants.UNDUH_CREATEXLS_FAILURE, error })
    )
  }
}
