import { modalConstants } from '../constants'

const initBody = (open) => {
  const body = document.querySelector('body')
  body.removeAttribute('style')
  if (open) body.style.overFlow = 'hidden'
}

export const modalActions = {
  login,
  advanceSearch,
  konfigurasiUnduh,
  narapidanaSearch,
  putusanSearch,
  report
}

function login(open) {
  initBody(open)

  let payload = { open }
  return (dispatch) => {
    dispatch({ type: modalConstants.LOGIN_TOGGLE, payload })
  }
}

function advanceSearch(open) {
  initBody(open)

  let payload = { open }
  return (dispatch) => {
    dispatch({ type: modalConstants.ADVANCE_SEARCH_TOGGLE, payload })
  }
}

function konfigurasiUnduh(open) {
  initBody(open)

  let payload = { open }
  return (dispatch) => {
    dispatch({ type: modalConstants.KONFIGURASI_UNDUH_TOGGLE, payload })
  }
}

function narapidanaSearch(open) {
  initBody(open)

  let payload = { open }
  return (dispatch) => {
    dispatch({ type: modalConstants.NARAPIDANA_SEARCH_TOGGLE, payload })
  }
}

function putusanSearch(open) {
  initBody(open)

  let payload = { open }
  return (dispatch) => {
    dispatch({ type: modalConstants.PUTUSAN_SEARCH_TOGGLE, payload })
  }
}

function report(open) {
  initBody(open)

  let payload = { open }
  return (dispatch) => {
    dispatch({ type: modalConstants.REPORT_TOGGLE, payload })
  }
}