import { settingConstants } from '../constants'
import { defaultState } from '../state'

export function setting(state = defaultState, action) {
  switch (action.type) {
    // GET
    case settingConstants.SETTING_GET_REQUEST:
      return {
        ...state,
        single: {
          ...state.single,
          loading: true,
        },
      }

    case settingConstants.SETTING_GET_SUCCESS:
      return {
        ...state,
        single: {
          data: action.res.data,
        },
      }

    case settingConstants.SETTING_GET_FAILURE:
      return {
        ...state,
        single: {
          error: action.error,
        },
      }

    // UPDATE
    case settingConstants.SETTING_UPDATE_REQUEST:
      return {
        ...state,
        update: {
          loading: true,
        },
      }

    case settingConstants.SETTING_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          result: action.res.data,
        },
      }

    case settingConstants.SETTING_UPDATE_FAILURE:
      return {
        ...state,
        update: {
          error: action.error,
        },
      }

    default:
      return state
  }
}
