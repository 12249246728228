import { chartConstants } from '../constants'
import { chartService } from '../services'
export const chartActions = {
  get,
  setDisplayMode,
}

function get(params = {}, callback) {
  return (dispatch) => {
    dispatch({ type: chartConstants.CHART_GET_REQUEST, params })
    chartService.get(params).then(
      (res) => {
        dispatch({ type: chartConstants.CHART_GET_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) => dispatch({ type: chartConstants.CHART_GET_FAILURE, error })
    )
  }
}

function setDisplayMode(type) {
  let payload = { type }

  localStorage.setItem('cds-display-mode', type)

  return (dispatch) => {
    dispatch({ type: chartConstants.CHART_TYPE, payload })
  }
}
