import { axios } from 'utils'

export const authService = {
  login,
  reset_password,
  update_password,
  validate_verify_code,
}

function login(data) {
  return axios.post(`/auth/signin`, data)
}

function reset_password(data) {
  return axios.post(`/auth/reset-password`, data)
}

function update_password(data) {
  return axios.put(`/auth/update-password`, data)
}

function validate_verify_code(verify_code) {
  return axios.get(`/auth/validate-verify-code/${verify_code}`)
}
