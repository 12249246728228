import { SubmitButton } from 'components/buttons'
import { Input } from 'components/fields'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { authActions } from 'redux/actions'

export default function Login({ goTo }) {
  const { getMe } = useContext(AuthContext)
  const { register, errors, handleSubmit, reset } = useForm()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const { t } = useTranslation()

  const beforeSubmit = (data) => {
    let params = {
      ...data,
    }

    dispatch(
      authActions.login(params, () => {
        getMe()
        reset({ username: '', password: '' })
      })
    )
  }

  return (
    <>
      <h2 className="h3 text-center" tabIndex="0" id="dialog-label">
        {t('Masuk')}
      </h2>
      <div className="row mt-4 mb-0">
        <div className="col-md-12">
          <form
            onSubmit={handleSubmit(beforeSubmit)}
            autoComplete="off"
            className="form-login"
          >
            <Input
              name="username"
              label={t('Email')}
              placeholder={t('Masukkan Email')}
              rules={{ required: true }}
              register={register}
              errors={errors}
            />

            <Input
              type="password"
              name="password"
              label={t('Kata Kunci')}
              placeholder={t('Masukkan Kata Kunci')}
              rules={{ required: true }}
              register={register}
              errors={errors}
            />

            <div className="form-group sm-m-t-30 text-center">
              <SubmitButton
                className="btn btn-lg btn-info btn-block"
                isLoading={auth.loading}
              >
                {t('Masuk')}
              </SubmitButton>
            </div>
          </form>

          <div className="row mt-3">
            <div className="col-12 text-center">
              {/* {t('Belum punya akun?')}
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    goTo('register')
                  }}
                  className="pl-1 text-primary"
                >
                  <span className="hint-text">{t('Daftar')}</span>
                </a>
                <br /> */}
              <button
                onClick={() => {
                  goTo('forgot-password')
                }}
                className="text-primary btn btn-link"
              >
                <span className="hint-text">{t('Lupa password anda?')}</span>
              </button>
              <div className="mt-2 well p-2 hint-text">
                {t('Apabila ingin bekerjasama dan mendapatkan akses ke data yang lebih detail, dapat menghubungi CDS melalui email')} :{' '}
                <span className="text-primary">
                  centerfordetentionstudies.ri@gmail.com
                </span>
              </div>
            </div>
          </div>
        </div>

        {/*
            <div className="col-md-6">
              <LoginThirdParty/>
            </div>
            */}
      </div>
    </>
  )
}
