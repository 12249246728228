export const roleConstants = {
  ADMIN: 1,
  OPERATOR: 2,
  USER: 3,
  GENERAL: 4,

  ROLE_GETALL_REQUEST: 'ROLE_GETALL_REQUEST',
  ROLE_GETALL_SUCCESS: 'ROLE_GETALL_SUCCESS',
  ROLE_GETALL_FAILURE: 'ROLE_GETALL_FAILURE',

  ROLE_GET_REQUEST: 'ROLE_GET_REQUEST',
  ROLE_GET_SUCCESS: 'ROLE_GET_SUCCESS',
  ROLE_GET_FAILURE: 'ROLE_GET_FAILURE',

  ROLE_CREATE_REQUEST: 'ROLE_CREATE_REQUEST',
  ROLE_CREATE_SUCCESS: 'ROLE_CREATE_SUCCESS',
  ROLE_CREATE_FAILURE: 'ROLE_CREATE_FAILURE',

  ROLE_UPDATE_REQUEST: 'ROLE_UPDATE_REQUEST',
  ROLE_UPDATE_SUCCESS: 'ROLE_UPDATE_SUCCESS',
  ROLE_UPDATE_FAILURE: 'ROLE_UPDATE_FAILURE',

  ROLE_DELETE_REQUEST: 'ROLE_DELETE_REQUEST',
  ROLE_DELETE_SUCCESS: 'ROLE_DELETE_SUCCESS',
  ROLE_DELETE_FAILURE: 'ROLE_DELETE_FAILURE',
}
