import { putusanConstants } from '../constants'
import { defaultState } from '../state'

let putusanState = {
  ...defaultState,
  details: {
    loading: false,
    data: null,
    error: null,
  },
  ringkasan: {
    loading: false,
    data: null,
    error: null,
  },
  verify: {
    ...defaultState.create,
  },
  download: {
    loading: false,
    data: null,
    error: null,
  },
  upload: {
    loading: false,
    data: null,
    error: null,
  },
}

export function putusan(state = putusanState, action) {
  switch (action.type) {
    // GET ALL
    case putusanConstants.PUTUSAN_GETALL_REQUEST:
      return {
        ...state,
        multi: {
          ...state.multi,
          loading: true,
        },
      }

    case putusanConstants.PUTUSAN_GETALL_SUCCESS:
      return {
        ...state,
        multi: {
          list: action.res.data,
          meta: action.res.meta,
        },
      }

    case putusanConstants.PUTUSAN_GETALL_FAILURE:
      return {
        ...state,
        multi: {
          error: action.error,
          list: [],
          meta: { total: 0 },
        },
      }

    // GET
    case putusanConstants.PUTUSAN_GET_REQUEST:
      return {
        ...state,
        single: {
          ...state.single,
          loading: true,
        },
      }

    case putusanConstants.PUTUSAN_GET_SUCCESS:
      return {
        ...state,
        single: {
          data: action.res.data,
        },
      }

    case putusanConstants.PUTUSAN_GET_FAILURE:
      return {
        ...state,
        single: {
          error: action.error,
        },
      }

    // RINGKASAN
    case putusanConstants.PUTUSAN_RINGKASAN_REQUEST:
      return {
        ...state,
        ringkasan: {
          ...state.ringkasan,
          loading: true,
        },
      }

    case putusanConstants.PUTUSAN_RINGKASAN_SUCCESS:
      return {
        ...state,
        ringkasan: {
          data: action.res.data,
        },
      }

    case putusanConstants.PUTUSAN_RINGKASAN_FAILURE:
      return {
        ...state,
        ringkasan: {
          error: action.error,
        },
      }

    // GET DETAILS
    case putusanConstants.PUTUSAN_DETAILS_REQUEST:
      return {
        ...state,
        details: {
          ...state.details,
          loading: true,
        },
      }

    case putusanConstants.PUTUSAN_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          data: action.res.data,
        },
      }

    case putusanConstants.PUTUSAN_DETAILS_FAILURE:
      return {
        ...state,
        details: {
          error: action.error,
        },
      }

    // DOWNLOAD
    case putusanConstants.PUTUSAN_DOWNLOAD_REQUEST:
      return {
        ...state,
        download: {
          loading: true,
        },
      }

    case putusanConstants.PUTUSAN_DOWNLOAD_SUCCESS:
      return {
        ...state,
        download: {
          result: action.res.data,
        },
      }
    case putusanConstants.PUTUSAN_DOWNLOAD_FAILURE:
      return {
        ...state,
        download: {
          error: action.error,
        },
      }

    default:
      return state
  }
}
