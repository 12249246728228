import { languageConstants } from '../constants'
export const languageActions = {
  set,
}

function set(lang) {
  let payload = { lang }

  localStorage.setItem('cds-language', lang)

  return (dispatch) => {
    dispatch({ lang: languageConstants.LANGUAGE_TYPE, payload })
  }
}
