import PropTypes from 'prop-types'
import { createContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { settingActions } from 'redux/actions'
export const AppContext = createContext()

export default function AppContextProvider({ children }) {
  const languageState = useSelector((state) => state.language)
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [appTitle, setAppTitle] = useState('')
  const [appSubTitle, setAppSubTitle] = useState('')
  const [footerDescription, setFooterDescription] = useState('')
  const [guideline, setGuideline] = useState('')
  const [sosmedLink, setSosmedLink] = useState({})

  useEffect(() => {
    i18n.changeLanguage(languageState.type);
    setIsLoading(true)
    let suffix = languageState.type === 'en'? "_en":"";

    settingActions.getDirect({ group: 'conf' }, (res) => {
      setAppTitle(res.data.find((d) => d.key === `title${suffix}`)?.value || '')
      setAppSubTitle(res.data.find((d) => d.key === `subtitle${suffix}`)?.value || '')
      setFooterDescription(
        res.data.find((d) => d.key === `footer_description${suffix}`)?.value || ''
      )
      setGuideline(res.data.find((d) => d.key === `guideline${suffix}`)?.value || '')
      setIsLoading(false)
      settingActions.getDirect({ group: 'link' }, (res) => {
        let links = {}
        for (let index = 0; index < res.data.length; index++) {
          const link = res.data[index];
          links[link.key] = link.value
        }
        setSosmedLink(links)
        setIsLoading(false)
      })
    })
  }, [])
  return (
    <AppContext.Provider
      value={{
        appTitle,
        appSubTitle,
        footerDescription,
        guideline,
        sosmedLink,
        isLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

AppContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
