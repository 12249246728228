import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Input } from '../fields'
import { withTranslation } from 'react-i18next'
import { SubmitButton } from '../buttons'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from 'redux/actions'
import { useTranslation } from 'react-i18next'

const ForgotPassword = (props) => {
  const [isSend, setIsSend] = useState(false)
  const { register, errors, handleSubmit } = useForm()
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const { t } = useTranslation()

  useEffect(() => {
    document.querySelector(".form-forgot-password input[name='email']").focus()

    return () => {
      setIsSend(false)
    }
  }, [])

  const beforeSubmit = (data) => {
    let params = {
      email: data.email,
    }

    dispatch(authActions.reset_password(params))
    setIsSend(true)
  }

  return (
    <>
      <h3 className="h3 text-center">{t('Setel Ulang Kata Sandi')}</h3>
      <div className="row mt-4">
        <div className="col-md-12">
          <form
            onSubmit={handleSubmit(beforeSubmit)}
            autoComplete="off"
            className="form-forgot-password"
          >
            <Input
              name="email"
              label={t('')}
              placeholder={t('Masukkan Email')}
              rules={{ required: true }}
              register={register}
              errors={errors}
            />
            <div className="form-group sm-m-t-30 text-center">
              {!auth.reset.loading && isSend ? (
                <div className="pt-2">
                {t('Email sudah dikirim, Silakan cek email Anda.')}
                </div>
              ) : (
                <SubmitButton
                  className="btn btn-lg btn-info btn-block"
                  isLoading={auth.reset.loading}
                >
                  {t('Reset')}
                </SubmitButton>
              )}
            </div>
          </form>

          <div className="row mt-4">
            <div className="col-12 text-center">
            {t('Kembali ke halaman')}
              <button
                onClick={() => {
                  props.goTo('login')
                }}
                className="text-primary btn btn-link"
              >
                <span className="hint-text">{t('Masuk')}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
