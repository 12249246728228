import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { menus } from 'config'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import Can from '../../auth/Can'

const Menu = () => {
  const location = useLocation()
  const [route] = useState(location.pathname)
  const { t } = useTranslation()

  return (
    <div className="menu-bar ml-auto header-sm-height" role="menubar">
      <ul role="menu" aria-label="Menu Utama">
        {menus.map((menu, index) => {
          let hasSubmenu = menu?.subs || false
          if (hasSubmenu) {
            const isActive = menu.to.find((to) => route.startsWith(to))
            const Menu = (
              <li
                role="none"
                className={`parent li-${menu.id} ` + (isActive ? 'active' : '')}
                key={index}
              >
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger asChild>
                    <a
                      href="/"
                      onClick={(e) => e.preventDefault()}
                      role="menuitem"
                      aria-haspopup="true"
                      aria-expanded="false"
                      tabIndex={0}
                    >
                      <span className="title">{t(menu.label)}</span>
                      <span className="arrow"></span>
                    </a>
                  </DropdownMenu.Trigger>

                  <DropdownMenu.Portal>
                    <DropdownMenu.Content
                      className="DropdownMenuContent py-2"
                      style={isActive ? { marginTop: 30 } : {}}
                    >
                      {menu.subs.map((sub) => {
                        const Submenu = (
                          <DropdownMenu.Item className="DropdownMenuItem px-3"  key={sub.label}>
                            <NavLink to={sub.to} role="menuitem" tabIndex={-1}>
                              {t(sub.label)}
                            </NavLink>
                          </DropdownMenu.Item>
                        )

                        // check permission
                        let hasRule = sub?.perform || false
                        if (hasRule) {
                          return (
                            <Can
                              key={sub.to}
                              perform={sub.perform}
                              yes={() => Submenu}
                              no={() => <></>}
                            />
                          )
                        } else {
                          return Submenu
                        }
                      })}
                    </DropdownMenu.Content>
                  </DropdownMenu.Portal>
                </DropdownMenu.Root>
              </li>
            )

            // check permission
            let hasRule = menu?.perform || false
            if (hasRule) {
              return (
                <Can
                  key={index}
                  perform={menu.perform}
                  yes={() => Menu}
                  no={() => <></>}
                />
              )
            } else {
              return Menu
            }
          } else {
            const active =
              menu.to === '/'
                ? route === menu.to
                  ? 'active'
                  : ''
                : route.startsWith(menu.to)
                ? 'active'
                : ''
            const Menu = (
              <li role="none" className={active} key={index}>
                <NavLink
                  to={menu.to}
                  className={active}
                  role="menuitem"
                  tabIndex={-1}
                >
                  <span className="title">{t(menu.label)}</span>
                </NavLink>
              </li>
            )

            // check permission
            let hasRule = menu?.perform || false
            if (hasRule) {
              return (
                <Can
                  key={index}
                  perform={menu.perform}
                  yes={() => Menu}
                  no={() => <></>}
                />
              )
            } else {
              return Menu
            }
          }
        })}
      </ul>
    </div>
  )
}

export default React.memo(Menu)
