export const newsConstants = {
  NEWS_GETALL_REQUEST: 'NEWS_GETALL_REQUEST',
  NEWS_GETALL_SUCCESS: 'NEWS_GETALL_SUCCESS',
  NEWS_GETALL_FAILURE: 'NEWS_GETALL_FAILURE',

  NEWS_GET_REQUEST: 'NEWS_GET_REQUEST',
  NEWS_GET_SUCCESS: 'NEWS_GET_SUCCESS',
  NEWS_GET_FAILURE: 'NEWS_GET_FAILURE',

  NEWS_CREATE_REQUEST: 'NEWS_CREATE_REQUEST',
  NEWS_CREATE_SUCCESS: 'NEWS_CREATE_SUCCESS',
  NEWS_CREATE_FAILURE: 'NEWS_CREATE_FAILURE',

  NEWS_UPDATE_REQUEST: 'NEWS_UPDATE_REQUEST',
  NEWS_UPDATE_SUCCESS: 'NEWS_UPDATE_SUCCESS',
  NEWS_UPDATE_FAILURE: 'NEWS_UPDATE_FAILURE',

  NEWS_DELETE_REQUEST: 'NEWS_DELETE_REQUEST',
  NEWS_DELETE_SUCCESS: 'NEWS_DELETE_SUCCESS',
  NEWS_DELETE_FAILURE: 'NEWS_DELETE_FAILURE',

  NEWS_UPLOAD_REQUEST: 'NEWS_UPLOAD_REQUEST',
  NEWS_UPLOAD_SUCCESS: 'NEWS_UPLOAD_SUCCESS',
  NEWS_UPLOAD_FAILURE: 'NEWS_UPLOAD_FAILURE',
}
