import { newsConstants } from '../constants'
import { defaultState } from '../state'

let newsState = {
  ...defaultState,
  download: {
    loading: false,
    data: null,
    error: null,
  },
}

export function news(state = newsState, action) {
  switch (action.type) {
    // GET ALL
    case newsConstants.NEWS_GETALL_REQUEST:
      return {
        ...state,
        multi: {
          ...state.multi,
          loading: true,
        },
      }

    case newsConstants.NEWS_GETALL_SUCCESS:
      return {
        ...state,
        multi: {
          list: action.res.data,
          meta: action.res.meta,
        },
      }

    case newsConstants.NEWS_GETALL_FAILURE:
      return {
        ...state,
        multi: {
          error: action.error,
          list: [],
          meta: { total: 0 },
        },
      }

    // GET
    case newsConstants.NEWS_GET_REQUEST:
      return {
        ...state,
        single: {
          ...state.single,
          loading: true,
        },
      }

    case newsConstants.NEWS_GET_SUCCESS:
      return {
        ...state,
        single: {
          data: action.res.data,
        },
      }

    case newsConstants.NEWS_GET_FAILURE:
      return {
        ...state,
        single: {
          error: action.error,
        },
      }

    // CREATE
    case newsConstants.NEWS_CREATE_REQUEST:
      return {
        ...state,
        create: {
          loading: true,
        },
      }
    case newsConstants.NEWS_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          result: action.res.data,
        },
      }
    case newsConstants.NEWS_CREATE_FAILURE:
      return {
        ...state,
        create: {
          error: action.error,
        },
      }

    // UPDATE
    case newsConstants.NEWS_UPDATE_REQUEST:
      return {
        ...state,
        update: {
          loading: true,
        },
      }

    case newsConstants.NEWS_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          result: action.res.data,
        },
      }

    case newsConstants.NEWS_UPDATE_FAILURE:
      return {
        ...state,
        update: {
          error: action.error,
        },
      }

    // DELETE
    case newsConstants.NEWS_DELETE_REQUEST:
      return {
        ...state,
        delete: {
          loading: true,
        },
      }

    case newsConstants.NEWS_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          result: action.res.data,
        },
      }
    case newsConstants.NEWS_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          error: action.error,
        },
      }

    // DOWNLOAD
    case newsConstants.NEWS_DOWNLOAD_REQUEST:
      return {
        ...state,
        download: {
          loading: true,
        },
      }

    case newsConstants.NEWS_DOWNLOAD_SUCCESS:
      return {
        ...state,
        download: {
          result: action.res.data,
        },
      }
    case newsConstants.NEWS_DOWNLOAD_FAILURE:
      return {
        ...state,
        download: {
          error: action.error,
        },
      }

    default:
      return state
  }
}
