import { axios } from 'utils'

export const publicationService = {
  getAll,
  get,
  download,
}

function getAll(params) {
  const page = params.page || 1
  const limit = params.limit || 10
  const start = (page - 1) * limit

  let queryString = `?s=${start}&l=${limit}&lang=${params.lang}`
  if (params.query) {
    queryString += `&q=${params.query}`
  }

  return axios.get(`/publication${queryString}`)
}

function get(param) {
  const uri = param.id
    ? `/publication/${param.id}`
    : `/publication/slug/${param.slug}?lang=${param.lang}`
  return axios.get(uri)
}

function download(param) {
  return axios.post(
    `/publication/download/slug/${param.slug}?lang=${param.lang}`,
    {},
    { responseType: 'blob' }
  )
}
