import { returneeConstants } from '../constants'
import { defaultState } from '../state'

let returneeState = {
  ...defaultState,
  details: {
    loading: false,
    data: null,
    error: null,
  },
  ringkasan: {
    loading: false,
    data: null,
    error: null,
  },
  verify: {
    ...defaultState.create,
  },
  download: {
    loading: false,
    data: null,
    error: null,
  },
  upload: {
    loading: false,
    data: null,
    error: null,
  },
}

export function returnee(state = returneeState, action) {
  switch (action.type) {
    // GET ALL
    case returneeConstants.RETURNEE_GETALL_REQUEST:
      return {
        ...state,
        multi: {
          ...state.multi,
          loading: true,
        },
      }

    case returneeConstants.RETURNEE_GETALL_SUCCESS:
      return {
        ...state,
        multi: {
          list: action.res.data,
          meta: action.res.meta,
        },
      }

    case returneeConstants.RETURNEE_GETALL_FAILURE:
      return {
        ...state,
        multi: {
          error: action.error,
          list: [],
          meta: { total: 0 },
        },
      }

    // GET
    case returneeConstants.RETURNEE_GET_REQUEST:
      return {
        ...state,
        single: {
          ...state.single,
          loading: true,
        },
      }

    case returneeConstants.RETURNEE_GET_SUCCESS:
      return {
        ...state,
        single: {
          data: action.res.data,
        },
      }

    case returneeConstants.RETURNEE_GET_FAILURE:
      return {
        ...state,
        single: {
          error: action.error,
        },
      }

    // CREATE
    case returneeConstants.RETURNEE_CREATE_REQUEST:
      return {
        ...state,
        create: {
          loading: true,
        },
      }
    case returneeConstants.RETURNEE_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          result: action.res.data,
        },
      }
    case returneeConstants.RETURNEE_CREATE_FAILURE:
      return {
        ...state,
        create: {
          error: action.error,
        },
      }

    // UPDATE
    case returneeConstants.RETURNEE_UPDATE_REQUEST:
      return {
        ...state,
        update: {
          loading: true,
        },
      }

    case returneeConstants.RETURNEE_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          result: action.res.data,
        },
      }

    case returneeConstants.RETURNEE_UPDATE_FAILURE:
      return {
        ...state,
        update: {
          error: action.error,
        },
      }

    // VERIFY
    case returneeConstants.RETURNEE_VERIFY_REQUEST:
      return {
        ...state,
        verify: {
          loading: true,
        },
      }

    case returneeConstants.RETURNEE_VERIFY_SUCCESS:
      return {
        ...state,
        verify: {
          result: action.res.data,
        },
      }
    case returneeConstants.RETURNEE_VERIFY_FAILURE:
      return {
        ...state,
        verify: {
          error: action.error,
        },
      }

    // DELETE
    case returneeConstants.RETURNEE_DELETE_REQUEST:
      return {
        ...state,
        delete: {
          loading: true,
        },
      }

    case returneeConstants.RETURNEE_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          result: action.res.data,
        },
      }
    case returneeConstants.RETURNEE_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          error: action.error,
        },
      }

    // DOWNLOAD
    case returneeConstants.RETURNEE_DOWNLOAD_REQUEST:
      return {
        ...state,
        download: {
          loading: true,
        },
      }

    case returneeConstants.RETURNEE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        download: {
          result: action.res.data,
        },
      }
    case returneeConstants.RETURNEE_DOWNLOAD_FAILURE:
      return {
        ...state,
        download: {
          error: action.error,
        },
      }

    default:
      return state
  }
}
