import React from 'react'

export const ResetButton = React.memo(({ className = '', reset, children }) => {
  return (
    <button
      aria-label=""
      className={`btn btn-danger btn-lg ${className}`}
      onClick={() => reset()}
      type="button"
    >
      {children}
    </button>
  )
})
