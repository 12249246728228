import { userConstants } from '../constants'
import { defaultState } from '../state'

let userState = {
  ...defaultState,
  me: {
    loading: false,
    data: null,
    error: null,
  },
}

export function user(state = userState, action) {
  switch (action.type) {
    // GET_ME
    case userConstants.USER_GET_ME_REQUEST:
      return {
        ...state,
        me: {
          ...state.me,
          loading: true,
        },
      }
    case userConstants.USER_GET_ME_SUCCESS:
      return {
        ...state,
        me: {
          data: action.res.data,
        },
      }
    case userConstants.USER_GET_ME_FAILURE:
      return {
        ...state,
        me: {
          error: action.error,
          data: null,
        },
      }

    // GET ALL
    case userConstants.USER_GETALL_REQUEST:
      return {
        ...state,
        multi: {
          ...state.multi,
          loading: true,
        },
      }

    case userConstants.USER_GETALL_SUCCESS:
      return {
        ...state,
        multi: {
          list: action.res.data,
          meta: action.res.meta,
        },
      }

    case userConstants.USER_GETALL_FAILURE:
      return {
        ...state,
        multi: {
          error: action.error,
          list: [],
          meta: { total: 0 },
        },
      }

    // GET
    case userConstants.USER_GET_REQUEST:
      return {
        ...state,
        single: {
          ...state.single,
          loading: true,
        },
      }

    case userConstants.USER_GET_SUCCESS:
      return {
        ...state,
        single: {
          data: action.res.data,
        },
      }

    case userConstants.USER_GET_FAILURE:
      return {
        ...state,
        single: {
          error: action.error,
        },
      }

    // CREATE
    case userConstants.USER_CREATE_REQUEST:
      return {
        ...state,
        create: {
          loading: true,
        },
      }
    case userConstants.USER_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          result: action.res,
        },
      }
    case userConstants.USER_CREATE_FAILURE:
      return {
        ...state,
        create: {
          error: action.error,
        },
      }

    // UPDATE
    case userConstants.USER_UPDATE_REQUEST:
      return {
        ...state,
        update: {
          loading: true,
        },
      }

    case userConstants.USER_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          result: action.res,
        },
      }

    case userConstants.USER_UPDATE_FAILURE:
      return {
        ...state,
        update: {
          error: action.error,
        },
      }

    // DELETE
    case userConstants.USER_DELETE_REQUEST:
      return {
        ...state,
        delete: {
          loading: true,
        },
      }

    case userConstants.USER_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          result: action.res,
        },
      }
    case userConstants.USER_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          error: action.error,
        },
      }

    default:
      return state
  }
}
