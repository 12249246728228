import { newsConstants, appConstants } from '../constants'
import { newsService } from '../services'
import { toastr } from 'react-redux-toastr'
export const newsActions = {
  getAll,
  get
}

function getAll(params = {}) {
  return (dispatch) => {
    dispatch({ type: newsConstants.NEWS_GETALL_REQUEST, params })
    newsService.getAll(params).then(
      (res) => {
        dispatch({
          type: newsConstants.NEWS_GETALL_SUCCESS,
          res,
        })
      },
      (error) =>
        dispatch({
          type: newsConstants.NEWS_GETALL_FAILURE,
          error,
        })
    )
  }
}

function get(param, callback) {
  return (dispatch) => {
    dispatch({ type: newsConstants.NEWS_GET_REQUEST, param })
    newsService.get(param).then(
      (res) => {
        dispatch({ type: newsConstants.NEWS_GET_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) => {
        dispatch({ type: newsConstants.NEWS_GET_FAILURE, error })
        if (error) toastr.error(appConstants.NOTIF_TITLE_ERROR, error)
      }
    )
  }
}
