import React from 'react'
import { useHistory } from 'react-router-dom'

export const BackButton = React.memo(({ children, className = '' }) => {
  let history = useHistory()
  return (
    <button
      aria-label=""
      className={`btn btn-light btn-lg ${className}`}
      type="button"
      onClick={() => history.goBack()}
    >
      {children}
    </button>
  )
})
