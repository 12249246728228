import { summaryConstants } from '../constants'

const defaultState = {
  event: {
    loading: false,
    data: null,
    error: null,
  },

  provinsi: {
    loading: false,
    data: null,
    error: null,
  },

  last_education: {
    loading: false,
    data: null,
    error: null,
  },

  last_job: {
    loading: false,
    data: null,
    error: null,
  },

  gender: {
    loading: false,
    data: null,
    error: null,
  },

  age: {
    loading: false,
    data: null,
    error: null,
  },

  marker: {
    loading: false,
    data: [],
    error: null,
  },
}

export function summary(state = defaultState, action) {
  switch (action.type) {
    // GET EVENT
    case summaryConstants.SUMMARY_GET_EVENT_REQUEST:
      return {
        ...state,
        event: {
          ...state.event,
          loading: true,
        },
      }

    case summaryConstants.SUMMARY_GET_EVENT_SUCCESS:
      return {
        ...state,
        event: {
          data: action.res.data,
        },
      }

    case summaryConstants.SUMMARY_GET_EVENT_FAILURE:
      return {
        ...state,
        event: {
          error: action.error,
        },
      }

    // GET PROVINSI
    case summaryConstants.SUMMARY_GET_PROVINSI_REQUEST:
      return {
        ...state,
        provinsi: {
          ...state.provinsi,
          loading: true,
        },
      }

    case summaryConstants.SUMMARY_GET_PROVINSI_SUCCESS:
      return {
        ...state,
        provinsi: {
          data: action.res.data,
        },
      }

    case summaryConstants.SUMMARY_GET_PROVINSI_FAILURE:
      return {
        ...state,
        provinsi: {
          error: action.error,
        },
      }

    // GET LAST_EDUCATION
    case summaryConstants.SUMMARY_GET_LAST_EDUCATION_REQUEST:
      return {
        ...state,
        last_education: {
          ...state.last_education,
          loading: true,
        },
      }

    case summaryConstants.SUMMARY_GET_LAST_EDUCATION_SUCCESS:
      return {
        ...state,
        last_education: {
          data: action.res.data,
        },
      }

    case summaryConstants.SUMMARY_GET_LAST_EDUCATION_FAILURE:
      return {
        ...state,
        last_education: {
          error: action.error,
        },
      }

    // GET LAST_JOB
    case summaryConstants.SUMMARY_GET_LAST_JOB_REQUEST:
      return {
        ...state,
        last_job: {
          ...state.last_job,
          loading: true,
        },
      }

    case summaryConstants.SUMMARY_GET_LAST_JOB_SUCCESS:
      return {
        ...state,
        last_job: {
          data: action.res.data,
        },
      }

    case summaryConstants.SUMMARY_GET_LAST_JOB_FAILURE:
      return {
        ...state,
        last_job: {
          error: action.error,
        },
      }

    // GET GENDER
    case summaryConstants.SUMMARY_GET_GENDER_REQUEST:
      return {
        ...state,
        gender: {
          ...state.gender,
          loading: true,
        },
      }

    case summaryConstants.SUMMARY_GET_GENDER_SUCCESS:
      return {
        ...state,
        gender: {
          data: action.res.data,
        },
      }

    case summaryConstants.SUMMARY_GET_GENDER_FAILURE:
      return {
        ...state,
        gender: {
          error: action.error,
        },
      }

    // GET AGE
    case summaryConstants.SUMMARY_GET_AGE_REQUEST:
      return {
        ...state,
        age: {
          ...state.age,
          loading: true,
        },
      }

    case summaryConstants.SUMMARY_GET_AGE_SUCCESS:
      return {
        ...state,
        age: {
          data: action.res.data,
        },
      }

    case summaryConstants.SUMMARY_GET_AGE_FAILURE:
      return {
        ...state,
        age: {
          error: action.error,
        },
      }

    // GET MARKER
    case summaryConstants.SUMMARY_GET_MARKER_REQUEST:
      return {
        ...state,
        marker: {
          ...state.marker,
          loading: true,
        },
      }

    case summaryConstants.SUMMARY_GET_MARKER_SUCCESS:
      return {
        ...state,
        marker: {
          data: action.res.data,
        },
      }

    case summaryConstants.SUMMARY_GET_MARKER_FAILURE:
      return {
        ...state,
        marker: {
          error: action.error,
        },
      }

    default:
      return state
  }
}
