import { authConstants, appConstants } from '../constants'
import { authService } from '../services'
import { toastr } from 'react-redux-toastr'

import { modalActions } from './modal.actions'
export const authActions = {
  login,
  logout,
  register,
  reset_password,
  update_password,
  validate_verify_code,
}

function login(data, callback) {
  let payload = { data }
  return (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST, payload })
    authService.login(data).then(
      (res) => {
        dispatch({ type: authConstants.LOGIN_SUCCESS, res })
        if (res.message) {
          toastr.success(appConstants.NOTIF_TITLE_SUCCESS, res.message)
        }

        localStorage.setItem('token', res.data.token)
        if (callback) callback()
        dispatch(modalActions.login(false))
      },
      (error) => {
        dispatch({ type: authConstants.LOGIN_FAILURE, error: error })
        toastr.error(
          appConstants.NOTIF_TITLE_ERROR,
          typeof error === 'object' ? 'Ada kesalahan input' : error
        )
      }
    )
  }
}

function logout(callback) {
  localStorage.removeItem('token')
  if (callback) callback()
  return { type: authConstants.LOGOUT }
}

function register(data) {
  let payload = { data }
  return (dispatch) => {
    dispatch({ type: authConstants.REGISTER_REQUEST, payload })
    authService.register(data).then(
      (res) => {
        dispatch({ type: authConstants.REGISTER_SUCCESS, res })
        if (res.message)
          toastr.success(appConstants.NOTIF_TITLE_SUCCESS, res.message)
      },
      (error) => dispatch({ type: authConstants.REGISTER_FAILURE, error })
    )
  }
}

function reset_password(data, callback) {
  let payload = { data }
  return (dispatch) => {
    dispatch({ type: authConstants.RESET_PASSWORD_REQUEST, payload })
    authService.reset_password(data).then(
      (res) => {
        dispatch({ type: authConstants.RESET_PASSWORD_SUCCESS, res })
        if (res.message) {
          toastr.success(appConstants.NOTIF_TITLE_SUCCESS, res.message)
        }

        if (callback) callback(res)
      },
      (error) => {
        dispatch({ type: authConstants.RESET_PASSWORD_FAILURE, error: error })
        toastr.error(
          appConstants.NOTIF_TITLE_ERROR,
          typeof error === 'object' ? 'Ada kesalahan input' : error
        )
      }
    )
  }
}

function validate_verify_code(verify_code, callback) {
  return () => {
    authService.validate_verify_code(verify_code).then(
      (res) => {
        if (callback) callback(res)
      },
      (error) => {
        if (callback) callback(error)
      }
    )
  }
}

function update_password(data, callback) {
  return () => {
    authService.update_password(data).then(
      (res) => {
        if (res.message)
          toastr.success(appConstants.NOTIF_TITLE_SUCCESS, res.message)

        if (callback) callback(res)
      },
      (error) => {
        if (callback) callback(error)
      }
    )
  }
}
