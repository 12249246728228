import React from 'react'
import { ErrorMessage } from './ErrorMessage'
import { default as TagsInputReact } from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import { Controller } from 'react-hook-form'

export const TagsInput = React.memo(
  ({
    name,
    label,
    control,
    errors,
    defaultValue = null,
    rules = {},
    placeholder = '',
  }) => {
    return (
      <>
        <div
          className={
            'form-group form-group-default' +
            (rules.required ? ' required ' : '') +
            (errors[name] ? ' has-error ' : '')
          }
          style={{ overflow: 'inherit' }}
        >
          <label>{label}</label>
          <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ onChange, value }) => {
              var val = Array.isArray(value) ? value : []
              return (
                <TagsInputReact
                  value={val}
                  onChange={(v) => onChange(v.length > 0 ? v : null)}
                  inputProps={{
                    placeholder,
                  }}
                  addOnBlur={true}
                />
              )
            }}
          />
        </div>
        <ErrorMessage errors={errors} name={name} label={label} />
      </>
    )
  }
)
