import forbidden from 'assets/img/forbidden.svg'
import Layout from 'components/layout/App'
import Breadcrumb from 'components/layout/partials/Breadcrumb'
import { useEffect } from 'react'
// import { emitter } from 'utils'

const ForbiddenPage = () => {
  useEffect(() => {
    // emitter.emit('auth/forbidden')
  }, [])

  return (
    <Layout className="bg-grey">
      <div className="row justify-content-center mt-3 mt-md-5 mb-0">
        <div className="col-md-10 mt-lg-5">
          <Breadcrumb list={[{ label: '403 Forbidden', active: true }]} />
        </div>
        <div className="col-md-10">
          <div className="card-block">
            <div className="row">
              <div className="col-lg-12">
                <article className="card text-center mt-5 card-transparent px-3 px-lg-0">
                  <h2 className="h4 title">403 Forbidden</h2>
                  <section>
                    <p>You Have No access to this page.</p>
                  </section>
                  <img
                    alt="forbidden"
                    src={forbidden}
                    className="m-auto block"
                    style={{ width: '200px' }}
                  />
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ForbiddenPage
