import React from 'react'
import { useTranslation } from 'react-i18next'

const LoginThirdParty = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="form-group mt-3 mt-lg-0">
        <button
          aria-label=""
          style={{ backgroundColor: '#dd4b39' }}
          className="btn btn-block btn-danger btn-lg"
          type="button"
        >
          <i className="pg-icon">google_plus</i>
          <span>{t('Masuk dengan Google+')}</span>
        </button>
      </div>
      <div className="form-group">
        <button
          aria-label=""
          style={{ backgroundColor: 'rgb(65 107 224)' }}
          className="btn btn-block btn-info btn-lg"
          type="button"
        >
          <i className="pg-icon">facebook</i>
          <span>{t('Masuk dengan Facebook')}</span>
        </button>
      </div>
    </>
  )
}

export default LoginThirdParty
