import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './i18n';

// redux
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './redux/reducers'
import ReduxToastr from 'react-redux-toastr'
import AuthControl from './components/auth/AuthControl'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import * as serviceWorker from './serviceWorkerRegistration'
import AuthContextProvider from 'contexts/AuthContext'
import AppContextProvider from 'contexts/AppContext'
const store = () => createStore(rootReducer, applyMiddleware(thunkMiddleware))

ReactDOM.render(
  <Provider store={store()}>
    <AuthContextProvider>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </AuthContextProvider>
    <ReduxToastr
      timeOut={4000}
      newestOnTop={true}
      preventDuplicates
      position="top-right"
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar
      closeOnToastrClick
    />
    <AuthControl />
  </Provider>,
  document.getElementById('root')
)

serviceWorker.register({
  onUpdate: (registration) => {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    window.location.reload()
  },
})
