export const faqConstants = {
  FAQ_GETALL_REQUEST: 'FAQ_GETALL_REQUEST',
  FAQ_GETALL_SUCCESS: 'FAQ_GETALL_SUCCESS',
  FAQ_GETALL_FAILURE: 'FAQ_GETALL_FAILURE',

  FAQ_GET_REQUEST: 'FAQ_GET_REQUEST',
  FAQ_GET_SUCCESS: 'FAQ_GET_SUCCESS',
  FAQ_GET_FAILURE: 'FAQ_GET_FAILURE',

  FAQ_CREATE_REQUEST: 'FAQ_CREATE_REQUEST',
  FAQ_CREATE_SUCCESS: 'FAQ_CREATE_SUCCESS',
  FAQ_CREATE_FAILURE: 'FAQ_CREATE_FAILURE',

  FAQ_UPDATE_REQUEST: 'FAQ_UPDATE_REQUEST',
  FAQ_UPDATE_SUCCESS: 'FAQ_UPDATE_SUCCESS',
  FAQ_UPDATE_FAILURE: 'FAQ_UPDATE_FAILURE',

  FAQ_DELETE_REQUEST: 'FAQ_DELETE_REQUEST',
  FAQ_DELETE_SUCCESS: 'FAQ_DELETE_SUCCESS',
  FAQ_DELETE_FAILURE: 'FAQ_DELETE_FAILURE',

  FAQ_SEQUENCE_REQUEST: 'FAQ_SEQUENCE_REQUEST',
  FAQ_SEQUENCE_SUCCESS: 'FAQ_SEQUENCE_SUCCESS',
  FAQ_SEQUENCE_FAILURE: 'FAQ_SEQUENCE_FAILURE',

  FAQ_GET_DISPLAY_REQUEST: 'FAQ_GET_DISPLAY_REQUEST',
  FAQ_GET_DISPLAY_SUCCESS: 'FAQ_GET_DISPLAY_SUCCESS',
  FAQ_GET_DISPLAY_FAILURE: 'FAQ_GET_DISPLAY_FAILURE',
}
