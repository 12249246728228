import { languageConstants } from '../constants'

function getType() {
  const types = ['id', 'en']
  const data = localStorage.getItem('cds-language');
  if (types.includes(data)) {
    return data
  } else return types[0]
}

const defaultState = {
  loading: false,
  type: getType(),
}

export function language(state = defaultState, action) {
  switch (action.type) {
    case languageConstants.LANGUAGE_TYPE:
      return {
        ...state,
        type: action.payload.type,
      }

    default:
      return state
  }
}
