
import { faqCategoryConstants } from '../constants'
import { defaultState } from '../state'

let faqCategoryState = {
  ...defaultState,
  download: {
    loading: false,
    data: null,
    error: null,
  },
}

export function faqCategory(state = faqCategoryState, action) {
  switch (action.type) {
    // GET ALL
    case faqCategoryConstants.FAQ_CATEGORY_GETALL_REQUEST:
      return {
        ...state,
        multi: {
          ...state.multi,
          loading: true,
        },
      }

    case faqCategoryConstants.FAQ_CATEGORY_GETALL_SUCCESS:
      return {
        ...state,
        multi: {
          list: action.res.data,
          meta: action.res.meta,
        },
      }

    case faqCategoryConstants.FAQ_CATEGORY_GETALL_FAILURE:
      return {
        ...state,
        multi: {
          error: action.error,
          list: [],
          meta: { total: 0 },
        },
      }

    // GET
    case faqCategoryConstants.FAQ_CATEGORY_GET_REQUEST:
      return {
        ...state,
        single: {
          ...state.single,
          loading: true,
        },
      }

    case faqCategoryConstants.FAQ_CATEGORY_GET_SUCCESS:
      return {
        ...state,
        single: {
          data: action.res.data,
        },
      }

    case faqCategoryConstants.FAQ_CATEGORY_GET_FAILURE:
      return {
        ...state,
        single: {
          error: action.error,
        },
      }

    // CREATE
    case faqCategoryConstants.FAQ_CATEGORY_CREATE_REQUEST:
      return {
        ...state,
        create: {
          loading: true,
        },
      }
    case faqCategoryConstants.FAQ_CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          result: action.res.data,
        },
      }
    case faqCategoryConstants.FAQ_CATEGORY_CREATE_FAILURE:
      return {
        ...state,
        create: {
          error: action.error,
        },
      }

    // UPDATE
    case faqCategoryConstants.FAQ_CATEGORY_UPDATE_REQUEST:
      return {
        ...state,
        update: {
          loading: true,
        },
      }

    case faqCategoryConstants.FAQ_CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          result: action.res.data,
        },
      }

    case faqCategoryConstants.FAQ_CATEGORY_UPDATE_FAILURE:
      return {
        ...state,
        update: {
          error: action.error,
        },
      }

    // DELETE
    case faqCategoryConstants.FAQ_CATEGORY_DELETE_REQUEST:
      return {
        ...state,
        delete: {
          loading: true,
        },
      }

    case faqCategoryConstants.FAQ_CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          result: action.res.data,
        },
      }
    case faqCategoryConstants.FAQ_CATEGORY_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          error: action.error,
        },
      }

    default:
      return state
  }
}
