export const status_putusan = [
  { value: 0, label: 'Menunggu Ekstraksi' },
  { value: 1, label: 'Menunggu Verifikasi' },
  { value: 2, label: 'Terverifikasi' },
  { value: 5, label: 'Menunggu Validasi File' },
  { value: 9, label: 'Gagal Ekstraksi' },
]

export const tingkat_pengadilan = [
  { value: 'PN', label: 'Pengadilan Negeri' },
  { value: 'PT', label: 'Pengadilan Tinggi' },
  { value: 'MA', label: 'Mahkamah Agung' }
]

export const status_returnee = [
  { value: 'DRAFT', label: 'DRAFT' },
  { value: 'VERIFIED', label: 'VERIFIED' },
]

export const is_returnee = [
  { value: '0', label: 'Narapidana' },
  { value: '1', label: 'Returnee' },
  { value: '2', label: 'Narapidana bersatatus Returnee' },
]

export const status_perjalanan = [
  { value: 'Gagal', label: 'Gagal' },
  { value: 'Berhasil', label: 'Berhasil' },
]

export const roles = [
  { value: 1, label: 'Administrator' },
  { value: 2, label: 'CDS' },
  { value: 3, label: 'APH' },
  { value: 4, label: 'Umum' },
]

export const pendidikan = [
  { value: 1, label: 'SD' },
  { value: 2, label: 'SMP dan Sederajat' },
  { value: 3, label: 'SMA dan Sederajat' },
  { value: 4, label: 'Diploma' },
  { value: 5, label: 'Sarjana' },
  { value: 6, label: 'Pasca-sarjana' },
  { value: 7, label: 'Tidak disebutkan' },
]

export const pekerjaan = [
  { value: 1, label: 'Tidak bekerja' },
  { value: 2, label: 'Pekerja kasar' },
  { value: 3, label: 'Pengusaha mandiri' },
  { value: 4, label: 'Karyawan/Pegawai' },
  { value: 5, label: 'Tenaga profesional' },
  { value: 6, label: 'ASN' },
  { value: 7, label: 'Pengusaha besar' },
  { value: 8, label: 'Anggota TNI / Polri' },
  { value: 9, label: 'Pejabat Tinggi' },
  { value: 10, label: 'Tidak disebutkan' },
]

export const jenis_kelamin = [
  { value: 'L', label: 'Laki-laki' },
  { value: 'P', label: 'Perempuan' },
]

export const kuasa_hukum = [
  { value: 'Tim Pengacara Muslim (TPM)', label: 'Tim Pengacara Muslim (TPM)' },
  { value: 'Tidak disebutkan', label: 'Tidak disebutkan' },
  { value: 'Lainnya', label: 'Lainnya' },
]

export const residivis = [
  { value: 'Residivis terorisme', label: 'Residivis terorisme' },
  { value: 'Residivis pidana umum', label: 'Residivis pidana umum' },
  { value: 'Bukan residivis', label: 'Bukan residivis' },
]

export const uu = [
  { value: 'UU No 5 Tahun 2018', label: 'UU No 5 Tahun 2018' },
  { value: 'UU No 15 Tahun 2003', label: 'UU No 15 Tahun 2003' },
]

export const tujuan_pemidanaan = [
  { value: 'Deterrance', label: 'Deterrance' },
  { value: 'Rehabilitatif/Integratif', label: 'Rehabilitatif/Integratif' },
  { value: 'Tidak disebutkan', label: 'Tidak disebutkan' },
]

export const jenis_serangan = [
  { value: 'Abstrak', label: 'Abstrak' },
  { value: 'Hard Target', label: 'Hard Target' },
  { value: 'Soft Target', label: 'Soft Target' },
]

export const jaringan_non_jaringan = [
  { value: 'Jaringan', label: 'Jaringan' },
  { value: 'Non Jaringan', label: 'Non Jaringan' },
]

export const usia_diputus = [
  { value: '>50', label: '>50' },
  { value: '41-50', label: '41-50' },
  { value: '31-40', label: '31-40' },
  { value: '18-30', label: '18-30' },
  { value: '<18 or (blank)', label: '<18 or (blank)' },
]

export const rentang_tuntutan = [
  { value: 'Tidak Tercantum', label: 'Tidak Tercantum' },
  { value: '1-50', label: '1-50' },
  { value: '51-100', label: '51-100' },
  { value: '101-150', label: '101-150' },
  { value: '151-200', label: '151-200' },
  { value: '>200', label: '>200' },
]

export const rentang_sanksi = [
  { value: '1-50', label: '1-50' },
  { value: '51-100', label: '51-100' },
  { value: '101-150', label: '101-150' },
  { value: '151-200', label: '151-200' },
  { value: '>200', label: '>200' },
]

export const wilayah_pengadilan = [
  { value: 'Jakarta Barat', label: 'Jakarta Barat' },
  { value: 'Jakarta Timur', label: 'Jakarta Timur' },
  { value: 'Jakarta Utara', label: 'Jakarta Utara' },
  { value: 'Jakarta Selatan', label: 'Jakarta Selatan' },
  { value: 'Jakarta Pusat', label: 'Jakarta Pusat' }
]

export const pasal = [
  { value: 'Pasal 12A UU 5/2018', label: 'Pasal 12A UU 5/2018' },
  { value: 'Pasal 12B UU 5/2018', label: 'Pasal 12B UU 5/2018' },
  { value: 'Pasal 12B UU 5/2018', label: 'Pasal 12B UU 5/2018' },
  { value: 'Pasal 13 c UU 5/2018', label: 'Pasal 13 c UU 5/2018' },
  { value: 'Pasal 13A UU 5/2018', label: 'Pasal 13A UU 5/2018' },
  { value: 'Pasal 15 jo 12A UU 5/2018', label: 'Pasal 15 jo 12A UU 5/2018' },
  { value: 'Pasal 15 jo 12B UU 5/2018', label: 'Pasal 15 jo 12B UU 5/2018' },
  { value: 'Pasal 15 jo 6 UU 5/2018', label: 'Pasal 15 jo 6 UU 5/2018' },
  { value: 'Pasal 15 jo 7 UU 5/2018', label: 'Pasal 15 jo 7 UU 5/2018' },
  {
    value: 'Pasal 5 jo Pasal 4 UU 9/2013',
    label: 'Pasal 5 jo Pasal 4 UU 9/2013',
  },
  { value: 'Pasal 4 UU 9/2013', label: 'Pasal 4 UU 9/2013' },
  { value: 'Pasal 9 UU 15/2003', label: 'Pasal 9 UU 15/2003' },
  { value: 'Pasal 13 c UU 15/2003', label: 'Pasal 13 c UU 15/2003' },
  {
    value: 'Pasal 15 jo Pasal 9 UU 15/2003',
    label: 'Pasal 15 jo Pasal 9 UU 15/2003',
  },
  {
    value: 'Pasal 15 jo Pasal 6 UU 15/2003',
    label: 'Pasal 15 jo Pasal 6 UU 15/2003',
  },
  {
    value: 'Pasal 15 jo Pasal 7 UU 15/2003',
    label: 'Pasal 15 jo Pasal 7 UU 15/2003',
  },
]

export const media_terpapar = [
  { value: 'Ajakan Tokoh Agama', label: 'Ajakan Tokoh Agama' },
  { value: 'Aplikasi', label: 'Aplikasi' },
  { value: 'Audio', label: 'Audio' },
  { value: 'Buku', label: 'Buku' },
  { value: 'Internet Website', label: 'Internet Website' },
  { value: 'Keluarga', label: 'Keluarga' },
  { value: 'Khotbah Salat Jumat', label: 'Khotbah Salat Jumat' },
  { value: 'Komunitas', label: 'Komunitas' },
  { value: 'Media Sosial', label: 'Media Sosial' },
  { value: 'Melalui Pernikahan Taaruf', label: 'Melalui Pernikahan Taaruf' },
  { value: 'Penjara', label: 'Penjara' },
  { value: 'Pertemanan', label: 'Pertemanan' },
  { value: 'Radio', label: 'Radio' },
  { value: 'Video', label: 'Video' },
  { value: 'Youtube', label: 'Youtube' },
  { value: 'Tidak disebutkan', label: 'Tidak disebutkan' },
]

export const perbuatan = [
  { value: 'Terlibat dalam jaringan', label: 'Terlibat dalam jaringan' },
  {
    value: 'Ikut dalam pelatihan militer',
    label: 'Ikut dalam pelatihan militer',
  },
  {
    value: 'Berencana untuk bergabung dengan kelompok teror di luar negeri',
    label: 'Berencana untuk bergabung dengan kelompok teror di luar negeri',
  },
  {
    value: 'Bersiap atau melakukan percobaan sebuah aksi teror',
    label: 'Bersiap atau melakukan percobaan sebuah aksi teror',
  },
  {
    value: 'Menyediakan, mengumpulkan atau meminjamkan dana untuk terorisme',
    label: 'Menyediakan, mengumpulkan atau meminjamkan dana untuk terorisme',
  },
  { value: 'Menyusun kegiatan idad', label: 'Menyusun kegiatan idad' },
  {
    value: 'Melakukan perekrutan anggota kelompok teror',
    label: 'Melakukan perekrutan anggota kelompok teror',
  },
  {
    value:
      'Memberi motivasi untuk melakukan aksi teror atau merupakan amir kelompok',
    label:
      'Memberi motivasi untuk melakukan aksi teror atau merupakan amir kelompok',
  },
  {
    value: "Bersiap melakukan aksi Fa'i",
    label: "Bersiap melakukan aksi Fa'i",
  },
  { value: "Melakukan Fa'i", label: "Melakukan Fa'i" },
  {
    value: 'Memberi bantuan fasilitas kepada teroris',
    label: 'Memberi bantuan fasilitas kepada teroris',
  },
  {
    value:
      'Dititipi senjata api, bom, atau hal-hal yang berkaitan dengan kegiatan terorisme',
    label:
      'Dititipi senjata api, bom, atau hal-hal yang berkaitan dengan kegiatan terorisme',
  },
  {
    value: 'Membantu melakukan pengeboman atau penyerangan',
    label: 'Membantu melakukan pengeboman atau penyerangan',
  },
  {
    value: 'Menjadi pelaku pengeboman atau penyerangan',
    label: 'Menjadi pelaku pengeboman atau penyerangan',
  },
  {
    value: 'Merusak bangunan atau benda atau fasilitas umum',
    label: 'Merusak bangunan atau benda atau fasilitas umum',
  },
  { value: 'Menyelundupkan buronan', label: 'Menyelundupkan buronan' },
  {
    value:
      'Bergabung dengan kelompok teror/berhijrah ke negeri lain untuk melaksanakan syariat islam',
    label:
      'Bergabung dengan kelompok teror/berhijrah ke negeri lain untuk melaksanakan syariat islam',
  },
  {
    value: 'Tidak jadi melakukan aksi teror karena kehendak sendiri',
    label: 'Tidak jadi melakukan aksi teror karena kehendak sendiri',
  },
  {
    value: 'Menimbulkan orang luka-luka',
    label: 'Menimbulkan orang luka-luka',
  },
  {
    value: 'Mengikuti pelatihan membuat bom dan senjata',
    label: 'Mengikuti pelatihan membuat bom dan senjata',
  },
  {
    value: 'Melatih membuat bom dan senjata',
    label: 'Melatih membuat bom dan senjata',
  },
  {
    value: 'Menimbulkan korban meninggal',
    label: 'Menimbulkan korban meninggal',
  },
  { value: 'Tidak jelas dalam putusan', label: 'Tidak jelas dalam putusan' },
  {
    value:
      'Menyalurkan uang untuk aktivitas kelompok teror atau anggota kelompok teror',
    label:
      'Menyalurkan uang untuk aktivitas kelompok teror atau anggota kelompok teror',
  },
  {
    value:
      'Menguasai, membawa, mempunyai persediaan padanya atau mempunyai dalam miliknya, menyimpan, mengangkut, menyembunyikan, mempergunakan, atau mengeluarkan ke dan/atau dari Indonesia sesuatu senjata api, amunisi, atau sesuatu bahan peledak dan bahan-bahan lainnya yang berbahaya dengan maksud untuk melakukan tindak pidana terorisme',
    label:
      'Menguasai, membawa, mempunyai persediaan padanya atau mempunyai dalam miliknya, menyimpan, mengangkut, menyembunyikan, mempergunakan, atau mengeluarkan ke dan/atau dari Indonesia sesuatu senjata api, amunisi, atau sesuatu bahan peledak dan bahan-bahan lainnya yang berbahaya dengan maksud untuk melakukan tindak pidana terorisme',
  },
  {
    value:
      'Menyebarluaskan materi atau konten propaganda terkait kelompok atau gerakan teroris',
    label:
      'Menyebarluaskan materi atau konten propaganda terkait kelompok atau gerakan teroris',
  },
  {
    value:
      'Menyembunyikan informasi tentang keberadaan pelaku terorisme',
    label:
      'Menyembunyikan informasi tentang keberadaan pelaku terorisme',
  },
  {
    value:
      'Tidak melaporkan adanya rencana aksi teror',
    label:
      'Tidak melaporkan adanya rencana aksi teror',
  },
  {
    value: 'Melakukan tindak pidana untuk pendanaan terorisme',
    label: 'Melakukan tindak pidana untuk pendanaan terorisme',
  },
  {
    value: 'Melibatkan anak-anak dalam tindak pidana terorisme',
    label: 'Melibatkan anak-anak dalam tindak pidana terorisme',
  },
  {
    value: 'Membantu kegiatan terorisme',
    label: 'Membantu kegiatan terorisme',
  },
  
]

export const motif = [
  { value: 'Balas dendam/Keluhan', label: 'Balas dendam/Keluhan' },
  { value: 'Kebutuhan akan ikatan', label: 'Kebutuhan akan ikatan' },
  { value: 'Kepercayaan', label: 'Kepercayaan' },
  { value: 'Efek Kekeluargaan', label: 'Efek Kekeluargaan' },
  { value: 'Keuntungan Finansial', label: 'Keuntungan Finansial' },
  { value: 'Tidak ada keterangan', label: 'Tidak ada keterangan' },
  // { value: 'Diajak teman', label: 'Diajak teman'},
  // { value: 'Efek Kekeluargaan', label: 'Efek Kekeluargaan'},
  // { value: 'Ingin hidup di bawah naungan Daulah Islamiyah (Negara yang menjalankan syariat Islam secara kaffah)', label: 'Ingin hidup di bawah naungan Daulah Islamiyah (Negara yang menjalankan syariat Islam secara kaffah)'},
  // { value: 'Ingin memperdalam ilmu agama', label: 'Ingin memperdalam ilmu agama'},
  // { value: 'Keluarganya memang jihadis', label: 'Keluarganya memang jihadis'},
  // { value: 'Menikmati keuntungan ekonomi', label: 'Menikmati keuntungan ekonomi'},
  // { value: 'Menjadi saksi kekerasan', label: 'Menjadi saksi kekerasan'},
  // { value: 'Perbuatan terdakwa tidak mendukung program pemerintah dalam pemberantasan tindak pidana terorisme', label: 'Perbuatan terdakwa tidak mendukung program pemerintah dalam pemberantasan tindak pidana terorisme'},
  // { value: 'Perbuatan yang dilakukan menimbulkan keresahan dan menciptakan ketakutan di masyarakat', label: 'Perbuatan yang dilakukan menimbulkan keresahan dan menciptakan ketakutan di masyarakat'},
  // { value: 'Tidak ada keterangan', label: 'Tidak ada keterangan'},
  // { value: 'Untuk mendapatkan pekerjaan', label: 'Untuk mendapatkan pekerjaan'}
]

export const baiat = [
  { value: 'Berbaiat', label: 'Berbaiat' },
  { value: 'Tidak Berbaiat', label: 'Tidak Berbaiat' },
]

export const jenis_baiat = [
  { value: 'Berbaiat sendiri', label: 'Berbaiat sendiri' },
  { value: 'Berbaiat kelompok', label: 'Berbaiat kelompok' },
  // { value: 'Dibaiat orang lain/kelompok', label: 'Dibaiat orang lain/kelompok'}
]

export const status_laporan = [
  { value: '0', label: 'Terima Laporan' },
  { value: '1', label: 'Proses Penanganan' },
  { value: '2', label: 'Selesai' }
]

export const jenis_pengeluaran = [
  { value: 'Bebas Murni', label: 'Bebas Murni' },
  { value: 'Pembebasan Bersyarat', label: 'Pembebasan Bersyarat' }
]

export const jenis_putusan_banding = [
  { value: 'Memperberat', label: 'Memperberat' },
  { value: 'Memperingan', label: 'Memperingan' },
  { value: 'Memperkuat', label: 'Memperkuat' },
  { value: 'Membebaskan', label: 'Membebaskan' }
]

export const filter_period = [
  { value: 'last week', label: '7 hari terakhir' },
  { value: 'last two week', label: '2 minggu terakhir' },
  { value: 'last month', label: '1 bulan terakhir' },
  { value: 'last three month', label: '3 bulan terakhir' },
  { value: 'last year', label: '1 tahun terakhir' }
]

export const pengajuan_banding = [
  { value: 'Mengajukan', label: 'Mengajukan' },
  { value: 'Tidak Mengajukan', label: 'Tidak Mengajukan' }
]

export const jenis_pidana = 
[
  { value: 'Terorisme', label: 'Terorisme' },
  { value: 'Non Terorisme', label: 'Non Terorisme' }
]