export const faqCategoryConstants = {
  FAQ_CATEGORY_GETALL_REQUEST: 'FAQ_CATEGORY_GETALL_REQUEST',
  FAQ_CATEGORY_GETALL_SUCCESS: 'FAQ_CATEGORY_GETALL_SUCCESS',
  FAQ_CATEGORY_GETALL_FAILURE: 'FAQ_CATEGORY_GETALL_FAILURE',

  FAQ_CATEGORY_GET_REQUEST: 'FAQ_CATEGORY_GET_REQUEST',
  FAQ_CATEGORY_GET_SUCCESS: 'FAQ_CATEGORY_GET_SUCCESS',
  FAQ_CATEGORY_GET_FAILURE: 'FAQ_CATEGORY_GET_FAILURE',

  FAQ_CATEGORY_CREATE_REQUEST: 'FAQ_CATEGORY_CREATE_REQUEST',
  FAQ_CATEGORY_CREATE_SUCCESS: 'FAQ_CATEGORY_CREATE_SUCCESS',
  FAQ_CATEGORY_CREATE_FAILURE: 'FAQ_CATEGORY_CREATE_FAILURE',

  FAQ_CATEGORY_UPDATE_REQUEST: 'FAQ_CATEGORY_UPDATE_REQUEST',
  FAQ_CATEGORY_UPDATE_SUCCESS: 'FAQ_CATEGORY_UPDATE_SUCCESS',
  FAQ_CATEGORY_UPDATE_FAILURE: 'FAQ_CATEGORY_UPDATE_FAILURE',

  FAQ_CATEGORY_DELETE_REQUEST: 'FAQ_CATEGORY_DELETE_REQUEST',
  FAQ_CATEGORY_DELETE_SUCCESS: 'FAQ_CATEGORY_DELETE_SUCCESS',
  FAQ_CATEGORY_DELETE_FAILURE: 'FAQ_CATEGORY_DELETE_FAILURE',

  FAQ_CATEGORY_SEQUENCE_REQUEST: 'FAQ_CATEGORY_SEQUENCE_REQUEST',
  FAQ_CATEGORY_SEQUENCE_SUCCESS: 'FAQ_CATEGORY_SEQUENCE_SUCCESS',
  FAQ_CATEGORY_SEQUENCE_FAILURE: 'FAQ_CATEGORY_SEQUENCE_FAILURE',
}
