import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from "./assets/locales/en/translation.json"
import id from "./assets/locales/id/translation.json"

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en:{
        translation: en
      },
      id:{
        translation: id
      },
    },
    lng: 'id', // Default language
    fallbackLng: 'id',
    interpolation: {
      escapeValue: false, // React already escapes the content
    },
  });

export default i18n;
