import React from 'react'
import { default as ReactSelect } from 'react-select'
import { Controller } from 'react-hook-form'
import { ErrorMessage } from './ErrorMessage'

export const Select = React.memo(
  ({
    name,
    label,
    errors,
    control,
    rules = {},
    placeholder = '',
    options,
    defaultValue = null,
    isMulti = false,
    classNamePrefix = 'react-select-form',
    isReadOnly = false,
    isDisabled = false
  }) => {
    return (
      <>
        <div
          className={
            'form-group form-group-default ' +
            (rules.required ? ' required ' : '') +
            (errors[name] ? ' has-error ' : '') +
            (isDisabled ? ' disabled ' : '')
          }
          style={{ overflow: 'inherit' }}
        >
          <label>{label}</label>
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ onChange, value }) => (
              <ReactSelect
                value={value}
                options={options}
                className="w-100"
                isMulti={isMulti}
                isSearchable={true}
                onChange={onChange}
                isClearable={true}
                placeholder={placeholder}
                classNamePrefix={classNamePrefix}
                isDisabled={isReadOnly}
              />
            )}
          />
        </div>
        <ErrorMessage errors={errors} name={name} label={label} />
      </>
    )
  }
)
