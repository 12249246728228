import React, { useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import MainLayout from './Main'
import Header from './partials/Header'
import Sidebar from './partials/Sidebar'
import Footer from './partials/Footer'

import $ from 'jquery'

const Layout = ({ children, className }) => {
  useLayoutEffect(() => {
    $('body').removeAttr('class')
    $('body').addClass('horizontal-menu horizontal-app-menu no-header')

    $('body').on('focus', '.form-group.form-group-default :input', function () {
      var type = $(this).attr('type')
      if (type === 'checkbox' || type === 'radio') {
        return
      }
      $('.form-group.form-group-default').removeClass('focused')
      $(this).parents('.form-group').addClass('focused')
    })

    $('body').on('blur', '.form-group.form-group-default :input', function () {
      var type = $(this).attr('type')
      if (type === 'checkbox' || type === 'radio') {
        return
      }
      $(this).parents('.form-group').removeClass('focused')
      if ($(this).val()) {
        $(this).closest('.form-group').find('label').addClass('fade')
      } else {
        $(this).closest('.form-group').find('label').removeClass('fade')
      }
    })
  }, [])

  return (
    <MainLayout>
      <Header className="d-none d-lg-block" />
      <Sidebar />
      <div className={'page-container '}>
        <Header className="d-lg-none" />
        <div className={'page-content-wrapper pb-0 mb-0 ' + className}>
          <div className="content">
            <div className="container-fixed-lg" style={{ minHeight: '74vh' }}>
              {children}
            </div>
          </div>
        </div>
        <Footer className="footer-app" />
      </div>
    </MainLayout>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  className: '',
}

export default React.memo(Layout)
