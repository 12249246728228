import React from 'react'

export const ErrorMessage = ({ errors, name, label }) => {
  if (Object.keys(errors).length === 0 && errors.constructor === Object) {
    return <></>
  }

  let error = null
  // mengecek apakah name mengandung index
  var indexes = name.match(/\[(.*?)\]/)
  if (indexes !== null) {
    let object = name.split('[')[0]
    let index = parseInt(indexes[1])
    let element = name.split('.')[1]

    if (!errors[object]) return <></>
    if (!errors[object][index]) return <></>
    if (!errors[object][index][element]) return <></>
    error = errors[object][index][element]
  } else {
    if (!errors[name]) return <></>
    error = errors[name]
  }

  const { type, message } = error

  if (type === 'required') {
    return <label className="error">{label} harus diisi</label>
  } else {
    return <label className="error">{message || ''}</label>
  }
}
