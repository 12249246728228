import { axios } from 'utils'

export const newsService = {
  getAll,
  get
}

function getAll(params) {
  const page = params.page || 1
  const limit = params.limit || 10
  const start = (page - 1) * limit

  let queryString = `?s=${start}&l=${limit}&lang=${params.lang}`
  if (params.query) {
    queryString += `&q=${params.query}`
  }

  return axios.get(`/news${queryString}`)
}

function get(param) {
  const uri = param.id
    ? `/news/${param.id}`
    : `/news/slug/${param.slug}?lang=${param.lang}`
  return axios.get(uri)
}