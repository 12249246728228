import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import LoginThirdParty from './LoginThirdParty'

class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { t } = this.props
    return (
      <>
        <h1 className="h3 text-center" id="dialog-label">
        {t('Daftar')}
        </h1>
        <div className="row mt-4">
          <div className="col-md-6">
            <form autoComplete="off">
              <div className="form-group form-group-default sm-m-t-30">
                <label>{t('Name')}</label>
                <div className="controls">
                  <input
                    type="text"
                    name="username"
                    placeholder={t("Masukkan Username")}
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="form-group form-group-default sm-m-t-30">
                <label>{t('Email')}</label>
                <div className="controls">
                  <input
                    type="text"
                    name="email"
                    placeholder={t('Masukkan Email')}
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="form-group form-group-default sm-m-t-30">
                <label>{t('Kata Kunci')}</label>
                <div className="controls">
                  <input
                    type="text"
                    name="password"
                    placeholder={t('Masukkan Kata Kunci')}
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="form-group form-group-default sm-m-t-30">
                <label>{t('Konfirmasi Kata Kunci')}</label>
                <div className="controls">
                  <input
                    type="text"
                    name="password"
                    placeholder={t('Masukkan Kata Kunci')}
                    className="form-control"
                    required
                  />
                </div>
              </div>

              <div className="row my-2">
                <div className="col-12">
                  <div className="small">
                  {t('Dengan mendaftar Anda telah menyetujui')}
                    <Link
                      onClick={this.props.handleClose}
                      to="/syarat-dan-kebijakan"
                      className="pl-1 text-primary"
                    >
                      <span className="hint-text">
                      {t('Syarat dan Kebijakan')}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="form-group sm-m-t-30">
                <button type="button" className="btn btn-lg btn-info btn-block">
                {t('Daftar')}
                </button>
              </div>
            </form>

            <div className="row mt-4">
              <div className="col-12 text-center">
              {t('Kembali ke halaman')}
                <button
                  onClick={() => {
                    this.props.goTo('login')
                  }}
                  className="text-primary btn btn-link"
                >
                  <span className="hint-text">{t('Login')}</span>
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <LoginThirdParty />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ modal, auth }) => {
  const { userLogin } = auth
  return { modal, userLogin }
}

export default withRouter(connect(mapStateToProps)(withTranslation()(Register)))
