import { roleConstants } from '../constants'
import { defaultState } from '../state'

let roleState = {
  ...defaultState,
}

export function role(state = roleState, action) {
  switch (action.type) {
    // GET ALL
    case roleConstants.ROLE_GETALL_REQUEST:
      return {
        ...state,
        multi: {
          ...state.multi,
          loading: true,
        },
      }

    case roleConstants.ROLE_GETALL_SUCCESS:
      return {
        ...state,
        multi: {
          list: action.res.data,
          meta: action.res.meta,
        },
      }

    case roleConstants.ROLE_GETALL_FAILURE:
      return {
        ...state,
        multi: {
          error: action.error,
          list: [],
          meta: { total: 0 },
        },
      }

    // GET
    case roleConstants.ROLE_GET_REQUEST:
      return {
        ...state,
        single: {
          ...state.single,
          loading: true,
        },
      }

    case roleConstants.ROLE_GET_SUCCESS:
      return {
        ...state,
        single: {
          data: action.res.data,
        },
      }

    case roleConstants.ROLE_GET_FAILURE:
      return {
        ...state,
        single: {
          error: action.error,
        },
      }

    // CREATE
    case roleConstants.ROLE_CREATE_REQUEST:
      return {
        ...state,
        create: {
          loading: true,
        },
      }

    case roleConstants.ROLE_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          result: action.res,
        },
      }

    case roleConstants.ROLE_CREATE_FAILURE:
      return {
        ...state,
        create: {
          error: action.error,
        },
      }

    // UPDATE
    case roleConstants.ROLE_UPDATE_REQUEST:
      return {
        ...state,
        update: {
          loading: true,
        },
      }

    case roleConstants.ROLE_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          result: action.res,
        },
      }

    case roleConstants.ROLE_UPDATE_FAILURE:
      return {
        ...state,
        update: {
          error: action.error,
        },
      }

    // DELETE
    case roleConstants.ROLE_DELETE_REQUEST:
      return {
        ...state,
        delete: {
          loading: true,
        },
      }

    case roleConstants.ROLE_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          result: action.res,
        },
      }
    case roleConstants.ROLE_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          error: action.error,
        },
      }
    default:
      return state
  }
}
