import { chartConstants } from '../constants'

function getType() {
  const types = ['grafik', 'tabel', ]
  const data = localStorage.getItem('cds-display-mode')
  if (types.includes(data)) {
    return data
  } else return types[0]
}
const defaultState = {
  loading: false,
  id: null,
  data: null,
  error: null,
  type: getType(),
}

export function chart(state = defaultState, action) {
  switch (action.type) {
    // GET
    case chartConstants.CHART_GET_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case chartConstants.CHART_GET_SUCCESS:
      return {
        ...state,
        id: action.res.id,
        data: action.res.data,
        loading: false,
      }

    case chartConstants.CHART_GET_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case chartConstants.CHART_TYPE:
      return {
        ...state,
        type: action.payload.type,
      }

    default:
      return state
  }
}
