import { combineReducers } from 'redux'

import { reducer as toastr } from 'react-redux-toastr'
import { auth } from './auth.reducer'
import { navigation } from './navigation.reducer'
import { modal } from './modal.reducer'

import { user } from './user.reducer'
import { role } from './role.reducer'
import { publication } from './publication.reducer'
import { news } from './news.reducer'
import { faqCategory } from './faq-category.reducer'
import { faq } from './faq.reducer'
import { faqDisplay } from './faq2.reducer'
import { putusan } from './putusan.reducer'
import { returnee } from './returnee.reducer'
import { putusan_draft } from './putusan.draft.reducer'
import { setting } from './setting.reducer'
import { report } from './report.reducer'
import { summary } from './summary.reducer'
import { chart } from './chart.reducer'
import { search } from './search.reducer'
import { unduh } from './unduh.reducer'
import { textSpeech} from './textSpeech.reducer';
import { statistic} from './statistic.reducer';
import { log} from './log.reducer';
import { language} from './language.reducer';

export default combineReducers({
  toastr,
  auth,
  navigation,
  modal,
  user,
  role,
  publication,
  news,
  faqCategory,
  faq,
  faqDisplay,
  putusan,
  putusan_draft,
  returnee,
  summary,
  setting,
  chart,
  search,
  unduh,
  report,
  textSpeech,
  statistic,
  log,
  language
})
