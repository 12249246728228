import { appConstants } from 'redux/constants'
import { toastr } from 'react-redux-toastr'
import { emitter } from '../emitter'

export const handleSuccess = (response) => {
  return response.data || response
}

export const handleError = (error) => {
  let response = {}
  if (error.response) {
    response = error.response
    let data = response.data
    if (response.status !== 200) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response was returned from api
        if (response.status === 401) {
          emitter.emit('auth/unauthorized')
        }
        toastr.error(appConstants.NOTIF_TITLE_ERROR, data.message)
      }
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }
  } else {
    let errorConnection = 'Cek kembali koneksi internet anda!'
    toastr.warning(appConstants.NOTIF_TITLE_ERROR, errorConnection, {
      timeOut: 0,
    })
    return Promise.reject(errorConnection)
  }
  return response
}
