import { axios } from 'utils'

export const userService = {
  getMe,
  update,
}

function getMe() {
  return axios.get(`/user/me`)
}

function update(data) {
  return axios.put(`/user/me`, data)
}
