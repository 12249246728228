
import { faqConstants } from '../constants'
import { defaultState } from '../state'

let faqState = {
  ...defaultState,
  download: {
    loading: false,
    data: null,
    error: null,
  },
}

export function faq(state = faqState, action) {
  switch (action.type) {
    // GET ALL
    case faqConstants.FAQ_GETALL_REQUEST:
      return {
        ...state,
        multi: {
          ...state.multi,
          loading: true,
        },
      }

    case faqConstants.FAQ_GETALL_SUCCESS:
      return {
        ...state,
        multi: {
          list: action.res.data,
          meta: action.res.meta,
        },
      }

    case faqConstants.FAQ_GETALL_FAILURE:
      return {
        ...state,
        multi: {
          error: action.error,
          list: [],
          meta: { total: 0 },
        },
      }

    // GET
    case faqConstants.FAQ_GET_REQUEST:
      return {
        ...state,
        single: {
          ...state.single,
          loading: true,
        },
      }

    case faqConstants.FAQ_GET_SUCCESS:
      return {
        ...state,
        single: {
          data: action.res.data,
        },
      }

    case faqConstants.FAQ_GET_FAILURE:
      return {
        ...state,
        single: {
          error: action.error,
        },
      }

    // CREATE
    case faqConstants.FAQ_CREATE_REQUEST:
      return {
        ...state,
        create: {
          loading: true,
        },
      }
    case faqConstants.FAQ_CREATE_SUCCESS:
      return {
        ...state,
        create: {
          result: action.res.data,
        },
      }
    case faqConstants.FAQ_CREATE_FAILURE:
      return {
        ...state,
        create: {
          error: action.error,
        },
      }

    // UPDATE
    case faqConstants.FAQ_UPDATE_REQUEST:
      return {
        ...state,
        update: {
          loading: true,
        },
      }

    case faqConstants.FAQ_UPDATE_SUCCESS:
      return {
        ...state,
        update: {
          result: action.res.data,
        },
      }

    case faqConstants.FAQ_UPDATE_FAILURE:
      return {
        ...state,
        update: {
          error: action.error,
        },
      }

    // DELETE
    case faqConstants.FAQ_DELETE_REQUEST:
      return {
        ...state,
        delete: {
          loading: true,
        },
      }

    case faqConstants.FAQ_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          result: action.res.data,
        },
      }
    case faqConstants.FAQ_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          error: action.error,
        },
      }

    default:
      return state
  }
}
