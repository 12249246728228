import { textSpeechConstants } from '../constants'

function getType() {
  const types = ['enable', 'disable']
  const data = localStorage.getItem('cds-text-speech-mode');
  if (types.includes(data)) {
    return data
  } else return types[1]
}
const defaultState = {
  loading: false,
  type: getType(),
}

export function textSpeech(state = defaultState, action) {
  switch (action.type) {
    // GET
    case textSpeechConstants.TEXT_SPEECH_GET_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case textSpeechConstants.TEXT_SPEECH_GET_SUCCESS:
      return {
        ...state,
        id: action.res.id,
        data: action.res.data,
        loading: false,
      }

    case textSpeechConstants.TEXT_SPEECH_GET_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      }

    case textSpeechConstants.TEXT_SPEECH_TYPE:
      return {
        ...state,
        type: action.payload.type,
      }

    default:
      return state
  }
}
