import React from 'react'
import { useLocation } from 'react-router-dom'
import Copyright from './Copyright'
import FooterLink from './FooterLink'

const Footer = ({ className }) => {
  const location = useLocation()

  return (
    <>
      {(location.pathname.startsWith('/publikasi') ||
        location.pathname === '/' ||
        location.pathname.startsWith('/berita') ||
        location.pathname === '/faq' ||
        location.pathname === '/about') && <FooterLink />}

      {/* <Copyright className={className} /> */}
    </>
  )
}

Footer.defaultProps = {
  className: '',
}

export default React.memo(Footer)
