import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const WrapTextToSpeech = (props) => {
  const textSpeechState = useSelector((state) => state.textSpeech)

  useEffect(() => {
    if (window.responsiveVoice) {
      window.responsiveVoice.cancel()
    }
  }, [textSpeechState.type])

  const getSelectedText = () => {
    if (textSpeechState.type === 'enable') {
      window.responsiveVoice.cancel()
      var selectedText = ''

      if (window.getSelection) {
        selectedText = window.getSelection()
      } else if (document.getSelection) {
        selectedText = document.getSelection()
      } else if (document.selection) {
        selectedText = document.selection.createRange().text
      } else return

      if (window.responsiveVoice) {
        if (selectedText.toString() !== '') {
          window.responsiveVoice.speak(
            selectedText.toString(),
            'Indonesian Female'
          )
        } else {
          window.responsiveVoice.cancel()
        }
      }
    }
  }

  return <div onMouseUp={() => getSelectedText()}>{props.children}</div>
}

export default WrapTextToSpeech
