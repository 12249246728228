import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { modalActions } from 'redux/actions'
import Register from './Register'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import { handleOpenModal } from 'utils/modal'

function AuthModal() {
  const id = 'AuthModal'
  const dispatch = useDispatch()
  const [formActive, setFormActive] = useState('login')
  const modal = useSelector((state) => state.modal)

  useEffect(() => {
    if (typeof modal.login !== 'undefined') {
      handleOpenModal(id, modal.login)

      if (modal.login) {
        const handleEscape = function (event) {
          var key = event.which || event.keyCode
          if (key === 27 && modal.login) {
            handleToggle()
          }
        }
        document.addEventListener('keyup', handleEscape)
        document.querySelector(".form-login input[name='username']").focus()
      }
    }
  }, [modal])

  const handleToggle = () => {
    dispatch(modalActions.login(!modal.login))
    handleSetFormActive('login')
  }

  const handleSetFormActive = (formName) => {
    setFormActive(formName)
  }

  return (
    <div
      className={'modal mt-5 fade stick-up '}
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-hidden={modal.login ? 'false' : 'true'}
      aria-labelledby="dialog-label"
      aria-modal="true"
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content-wrapper">
          <div className="modal-content">
            <div className="modal-header px-3">
              <button
                type="button"
                onClick={handleToggle}
                className="close"
                style={{ right: '11px' }}
              >
                <i className="pg-icon">close</i>
              </button>
            </div>

            <div className="modal-body px-3 mt-2 pb-3">
              {formActive === 'login' && <Login goTo={handleSetFormActive} />}

              {formActive === 'register' && (
                <Register
                  goTo={handleSetFormActive}
                  handleClose={handleToggle}
                />
              )}

              {formActive === 'forgot-password' && (
                <ForgotPassword goTo={handleSetFormActive} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AuthModal)
