import { axios } from 'utils'

export const statisticService = {
  send,
  getUserStatistic,
  getSummary,
  getTopPage,
  getVisitorStatistic,
  getCountryStatistic,
}

function send(payload) {
  return axios.post(`/statistic/access`, payload)
}

function getUserStatistic(payload) {
  return axios.post(`/statistic/user`, payload)
}

function getSummary(payload) {
  return axios.post(`/statistic/summary`, payload)
}

function getTopPage(payload) {
  return axios.post(`/statistic/top-page`, payload)
}

function getVisitorStatistic(payload) {
  return axios.post(`/statistic/visitor`, payload)
}

function getCountryStatistic(payload) {
  return axios.post(`/statistic/country`, payload)
}
