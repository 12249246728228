import { publicationConstants, appConstants } from '../constants'
import { publicationService } from '../services'
import { toastr } from 'react-redux-toastr'
export const publicationActions = {
  getAll,
  get,
  download,
}

function getAll(params = {}) {
  return (dispatch) => {
    dispatch({ type: publicationConstants.PUBLICATION_GETALL_REQUEST, params })
    publicationService.getAll(params).then(
      (res) => {
        dispatch({
          type: publicationConstants.PUBLICATION_GETALL_SUCCESS,
          res,
        })
      },
      (error) =>
        dispatch({
          type: publicationConstants.PUBLICATION_GETALL_FAILURE,
          error,
        })
    )
  }
}

function get(param, callback) {
  return (dispatch) => {
    dispatch({ type: publicationConstants.PUBLICATION_GET_REQUEST, param })
    publicationService.get(param).then(
      (res) => {
        dispatch({ type: publicationConstants.PUBLICATION_GET_SUCCESS, res })
        if (callback) callback(res)
      },
      (error) => {
        dispatch({ type: publicationConstants.PUBLICATION_GET_FAILURE, error })
        if (error) toastr.error(appConstants.NOTIF_TITLE_ERROR, error)
      }
    )
  }
}

function download(param, callback) {
  return (dispatch) => {
    dispatch({
      type: publicationConstants.PUBLICATION_DOWNLOAD_REQUEST,
      param,
    })
    publicationService.download(param).then(
      (res) => {
        dispatch({
          type: publicationConstants.PUBLICATION_DOWNLOAD_SUCCESS,
          res,
        })
        if (callback) callback(res)
      },
      (error) => {
        dispatch({
          type: publicationConstants.PUBLICATION_DOWNLOAD_FAILURE,
          error,
        })
        if (error) toastr.error(appConstants.NOTIF_TITLE_ERROR, error)
      }
    )
  }
}
