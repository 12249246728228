import { axios } from 'utils'

export const searchService = {
  get,
  getProvinsi,
  getKabkota,
  getSearchKabkota,
  getJaringan,
  getTahunPutusan,
  getHakim,
  getMotif,
  getWilayahPengadilan,
  getPerbuatan,
  getPasal,
  getPutusan,
  getPutusanBandingNama
}

function get(params) {
  const { type, value } = params
  if (type === 'putusan') {
    return axios.post(`/data/search/keyword/${value}`)
  } else {
    return axios.post(`/data/search/narapidana/keyword/${value}`)
  }
}

function getProvinsi() {
  return axios.get(`/data/search/provinsi`)
}

function getKabkota(params) {
  return axios.get(`/data/search/kabkota`)
}

function getSearchKabkota(namaKota) {
  return axios.get(`/data/search/kabkota/${namaKota}`)
}

function getJaringan() {
  return axios.get(`/data/search/jaringan`)
}

function getTahunPutusan() {
  return axios.get(`/data/search/tahun-putusan`)
}

function getHakim() {
  return axios.get(`/data/search/hakim`)
}

function getMotif() {
  return axios.get(`/data/search/motif`)
}

function getWilayahPengadilan() {
  return axios.get(`/data/search/wilayah-pengadilan`)
}

function getPerbuatan() {
  return axios.get(`/data/search/perbuatan`)
}

function getPasal() {
  return axios.get(`/data/search/pasal`)
}

function getPutusan(payload) {
  return axios.post(`/data/search/putusan`, payload)
}

function getPutusanBandingNama(payload) {
  return axios.post(`/data/search/putusan-banding-nama`, payload)
}