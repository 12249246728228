import React, { Suspense, useEffect } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import ReactGA from "react-ga4";
import SuspensePage from 'components/commons/Suspense'
import { createBrowserHistory } from 'history'
import WrapTextToSpeech from 'components/textToSpeech'

const Beranda = React.lazy(() => import('./pages/Beranda'))
const Dashboard = React.lazy(() => import('./pages/Dashboard'))
const Publikasi = React.lazy(() => import('./pages/Publikasi'))
const Berita = React.lazy(() => import('./pages/Berita'))
const Unduh = React.lazy(() => import('./pages/Unduh'))
const Master = React.lazy(() => import('./pages/Master'))
const Profile = React.lazy(() => import('./pages/Profile'))
const Auth = React.lazy(() => import('./pages/Auth'))
const About = React.lazy(() => import('./pages/About'))
const FAQ = React.lazy(() => import('./pages/FAQ'))
const Putusan = React.lazy(() => import('./pages/Putusan'))
const NotFoundPage = React.lazy(() => import('./pages/404'))
const history = createBrowserHistory()

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);

history.listen(location => {
  ReactGA.send({ hitType: "pageview", page: location.pathname });
});


export default function App() {

  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <WrapTextToSpeech>
      <Router history={history}>
        <Suspense fallback={<SuspensePage />}>
          <Switch>
            <Route path="/" exact component={Beranda} />
            <Route path="/data" component={Dashboard} />
            <Route path="/putusan" component={Putusan} />
            <Route path="/publikasi" component={Publikasi} />
            <Route path="/berita" component={Berita} />
            <Route path="/unduh" component={Unduh} />
            <Route path="/master" component={Master} />
            <Route path="/profile" component={Profile} />
            <Route path="/auth" component={Auth} />
            <Route path="/about" component={About} />
            <Route path="/faq" component={FAQ} />
            <Route path="/master" component={Master} />
            <Route path="/404" component={NotFoundPage} />
            <Redirect to="/404" />
          </Switch>
        </Suspense>
      </Router>
    </WrapTextToSpeech>
  )
}
