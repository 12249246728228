import React from 'react'

const Copyright = ({ className }) => {
  return (
    <div className={'footer d-print-none pt-3 pb-3 ' + className}>
      <div className="container container-fixed-lg copyright sm-text-center">
        <p className="small no-margin pull-left sm-pull-reset">
          <span className="hint-text">Copyright &copy; 2020 </span>
          <span className="">Center for Detention Studies. </span>
          <span className="hint-text d-md-inline d-block">
            All rights reserved.{' '}
          </span>
        </p>
        <p className={'small no-margin pull-right sm-pull-reset '}>
          <span className="hint-text">Powered by </span>
          <span className="">ALUTECHNO.IO</span>
        </p>
        <div className="clearfix"></div>
      </div>
    </div>
  )
}

Copyright.defaultProps = {
  className: '',
}

export default React.memo(Copyright)
