import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { languageActions } from 'redux/actions'

const languages = [
    { code: 'id', name: 'Bahasa Indonesia'},
    { code: 'en', name: 'Bahasa Inggris'}
]

function LanguageSwitcherSelector(props) {

    const onChange = e => {
        props.handleChangeLanguage(e.target.className);
    }

    const options = languages.map(language => {
        if(language.code !== props.lang){
            return <li onClick={onChange} key={language.code}><div value={language.code} className={language.code} ></div></li>
        }  
    });

    return (
        <div className="lang"> 
            <div className={props.lang}></div>
            <ul className="dropdown">{options}</ul>
        </div>
    )
}

function LanguageSwitcher () {
    const languageState = useSelector((state) => state.language)
    const { i18n } = useTranslation();
    const [lang, setLang] = useState(languageState.type)
    
    const changeLanguageHandler = (lang) => {
        setLang(lang);
        i18n.changeLanguage(lang);
        languageActions.set(lang);
        window.location.reload(true);
    }

    return (
        <div className="languageSwitcher">
            <LanguageSwitcherSelector 
                lang={lang}
                handleChangeLanguage={changeLanguageHandler}
            />
        </div>
    )
}

export default React.memo(LanguageSwitcher)