import React from 'react'
import { ErrorMessage } from './ErrorMessage'

export const Checkbox = React.memo(
  ({
    name,
    label,
    register,
    errors,
    rules = {},
    options = [],
    type = 'checkbox',
    style = {},
  }) => {
    return (
      <>
        <div
          className={
            'form-group form-group-default border py-2 px-2' +
            (rules.required ? ' required ' : '') +
            (errors[name] ? ' has-error ' : '')
          }
          style={style}
        >
          <label>{label}</label>
          {/* <br /> */}
          {options.map((option, index) => (
            <div className="form-check form-check-inline complete" key={index}>
              {type === 'checkbox' && (
                <input
                  type="checkbox"
                  name={`${name}[${index}]`}
                  ref={register(rules)}
                  id={`${name}${index}`}
                  value={option.value}
                />
              )}
              {type === 'radio' && (
                <input
                  type="radio"
                  name={name}
                  ref={register(rules)}
                  id={`${name}${index}`}
                  value={option.value}
                />
              )}
              <label htmlFor={`${name}${index}`}>{option.label}</label>
            </div>
          ))}
        </div>
        <ErrorMessage errors={errors} name={name} label={label} />
      </>
    )
  }
)
