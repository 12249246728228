export const handleOpenModal = (id, open) => {
  if (typeof open === 'undefined') return false

  const body = document.querySelector('body')
  const modal = document.querySelector(`#${id}`)

  if (open) {
    body.classList.add('modal-open')
    modal.style.display = 'block'

    let backdrop = document.querySelector(`#modal-backdrop-${id}`)

    if (!backdrop) {
      backdrop = document.createElement('div')
      backdrop.setAttribute('id', `modal-backdrop-${id}`)
      backdrop.classList.add('modal-backdrop', 'fade')
    }

    backdrop.style.display = 'block'
    document.body.appendChild(backdrop)

    setTimeout(() => {
      if (body.classList.contains('modal-open')) modal.classList.add('show')
      if (body.classList.contains('modal-open')) backdrop.classList.add('show')
    }, 100)
  } else {
    const backdrop = document.querySelector(`#modal-backdrop-${id}`)
    body.classList.remove('modal-open')

    if (modal) modal.classList.remove('show')
    if (backdrop) backdrop.classList.remove('show')

    setTimeout(() => {
      if (modal && !modal.classList.contains('show'))
        modal.style.display = 'none'
      if (backdrop && !modal.classList.contains('show')) {
        backdrop.style.display = 'none'
        backdrop.remove()
      }
    }, 350)
  }
}
