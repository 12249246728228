import { axios } from 'utils'

export const unduhService = {
  getAll,
  download,
}

function getAll(params) {
  const page = params.page || 1
  const limit = params.limit || 10
  const start = (page - 1) * limit

  let queryString = `?s=${start}&l=${limit}`
  if (params.query) {
    queryString += `&${params.query}`
  }
  if (params.draft) {
    queryString += `&draft=true`
  }

  return axios.get(`/unduh${queryString}`)
}

function download(params) {
  const page = params.page || 1
  const limit = params.limit || 10
  const start = (page - 1) * limit

  let queryString = `?s=${start}&l=${limit}`
  if (params.query) {
    queryString += `&${params.query}`
  }
  if (params.draft) {
    queryString += `&draft=true`
  }

  return axios.post(
    `/unduh/download${queryString}`,
    { fields: params.fields },
    {
      responseType: 'blob',
    }
  )
}
