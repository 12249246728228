import React from 'react'

export const ActionButton = React.memo(
  ({
    className = 'btn btn-outline-primary',
    onClick,
    children,
    isLoading,
    classIcon,
    isDisabled = false,
  }) => {
    return (
      <button
        onClick={() => {
          if (!isLoading && !isDisabled) onClick()
        }}
        className={className + (isLoading || isDisabled ? ' disabled' : '')}
        type="button"
        disabled={isLoading}
      >
        {isLoading ? (
          <div
            className="spinner-border spinner-border-sm mr-1"
            role="status"
            aria-hidden="true"
          ></div>
        ) : (
          <>{classIcon && <i className={classIcon + ' mr-2'}></i>}</>
        )}
        {children}
      </button>
    )
  }
)
